import { ComponentType, FC } from 'react'

import CustomerProvider from '../components/BuyerProfile/Account/CustomerProvider'
import { StripeProvider } from '../components/Stripe/StripeContext'

const withCustomer = <Props extends {}>(
  WrappedComponent: ComponentType<Props>,
) => {
  const WithCustomer: FC<Props> = (props) => (
    <StripeProvider>
      <CustomerProvider>
        <WrappedComponent {...props} />
      </CustomerProvider>
    </StripeProvider>
  )

  return WithCustomer
}

export default withCustomer
