import { IconProps } from '@rsuite/icons/lib/Icon'
import { FC } from 'react'

const PhoneIcon2: FC<IconProps> = ({ className }) => (
  <svg
    className={`phone-icon ${className}`}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0014 15.8508V18.8508C21.0025 19.1293 20.9455 19.4049 20.8339 19.6601C20.7223 19.9153 20.5587 20.1443 20.3535 20.3326C20.1483 20.5209 19.906 20.6642 19.6421 20.7535C19.3783 20.8427 19.0988 20.8758 18.8214 20.8508C15.7442 20.5164 12.7884 19.4649 10.1914 17.7808C7.77523 16.2454 5.72673 14.1969 4.1914 11.7808C2.50138 9.17197 1.44964 6.20176 1.1214 3.11076C1.09641 2.83423 1.12927 2.55552 1.2179 2.29239C1.30652 2.02925 1.44897 1.78745 1.63616 1.58238C1.82336 1.37731 2.0512 1.21347 2.30519 1.10128C2.55917 0.989096 2.83374 0.931022 3.1114 0.930761H6.1114C6.5967 0.925985 7.06719 1.09784 7.43516 1.41429C7.80313 1.73075 8.04348 2.17021 8.1114 2.65076C8.23802 3.61083 8.47285 4.55349 8.8114 5.46076C8.94594 5.81868 8.97506 6.20768 8.8953 6.58164C8.81555 6.95561 8.63026 7.29887 8.3614 7.57076L7.0914 8.84076C8.51495 11.3443 10.5879 13.4172 13.0914 14.8408L14.3614 13.5708C14.6333 13.3019 14.9766 13.1166 15.3505 13.0369C15.7245 12.9571 16.1135 12.9862 16.4714 13.1208C17.3787 13.4593 18.3213 13.6941 19.2814 13.8208C19.7672 13.8893 20.2108 14.134 20.5279 14.5083C20.8451 14.8825 21.0136 15.3603 21.0014 15.8508Z"
      stroke="#0F2137"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

)

export default PhoneIcon2
