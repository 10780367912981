import TagManager from 'react-gtm-module'

import { roundToTwoDecimals } from '../math'
import { emptyEcomDataLayer, getCartTotalDiscount, getCartTotalValue, getCartEcomEventItems } from './utils'
import { CartResponse, WithSuppliers } from '../types/Order'
import { ActiveCountryId } from '../constants'
import { Product } from '../types/Product'

interface TriggerCheckoutAbandonmentFlow {
  email: string
  cartData: WithSuppliers<CartResponse>
  country: ActiveCountryId
  getLocalizedProductUrl: (product?: Product) => string
  isGuest: boolean
  isVatIncluded: boolean
}

// Used to trigger checkout abandonment flow for Ortto marketing automation
const triggerCheckoutAbandonmentFlow = (params: TriggerCheckoutAbandonmentFlow) => {
  const {
    email = '',
    cartData,
    country,
    getLocalizedProductUrl,
    isGuest,
    isVatIncluded,
  } = params

  emptyEcomDataLayer() // First empty datalayer

  TagManager.dataLayer({ dataLayer: {
    event: 'trigger_checkout_abandonment_flow',
    email,
    is_guest_user: isGuest,
    ecommerce: cartData
      ? {
        value: roundToTwoDecimals(cartData.data?.attributes['order.price'] || 0),
        tax: roundToTwoDecimals(cartData.data?.attributes['order.taxvalue'] || 0),
        shipping: roundToTwoDecimals(cartData.data?.attributes['order.costs'] || 0),
        currency: cartData.data?.attributes['order.currencyid'],
        totalValue: getCartTotalValue(cartData),
        discount: getCartTotalDiscount(cartData),
        items: getCartEcomEventItems({
          cartData,
          country,
          getLocalizedProductUrl,
          isVatIncluded,
        }),
      }
      : null,
  } })
}

export default triggerCheckoutAbandonmentFlow
