import TagManager from 'react-gtm-module'

import { roundToTwoDecimals } from '../math'
import { emptyEcomDataLayer, getCartTotalDiscount, getCartTotalValue, getCartEcomEventItems } from './utils'
import { CartResponse, WithSuppliers } from '../types/Order'
import { getOrderPaymentData } from '../../lib/OrderHistory'
import { Product } from '../types/Product'

interface PurchaseProps {
  orderData: WithSuppliers<CartResponse>
  orderId: string
  sendToProductionAnalytics?: boolean
  isFirstPurchase?: boolean
  getLocalizedProductUrl: (product?: Product) => string
  isVatIncluded: boolean
}

const purchase = (props: PurchaseProps) => {
  const {
    orderData,
    orderId,
    sendToProductionAnalytics = false,
    isFirstPurchase,
    getLocalizedProductUrl,
    isVatIncluded,
  } = props
  const { data } = orderData

  if (data) {
    const items = getCartEcomEventItems({
      cartData: orderData,
      getLocalizedProductUrl,
      isVatIncluded,
    })
    const { invoiceType } = getOrderPaymentData(orderData)

    emptyEcomDataLayer() // First empty datalayer
    TagManager.dataLayer({ dataLayer: {
      send_to_production_analytics: sendToProductionAnalytics,
      event: 'purchase',
      ecommerce: {
        affiliation: 'Droppe Marketplace',
        transaction_id: orderId,
        value: roundToTwoDecimals(data.attributes['order.price']),
        tax: roundToTwoDecimals(data.attributes['order.taxvalue']),
        shipping: roundToTwoDecimals(data.attributes['order.costs']),
        currency: data.attributes['order.currencyid'],
        coupon: 'none',
        totalValue: getCartTotalValue(orderData),
        discount: getCartTotalDiscount(orderData),
        items,
        payment_type: invoiceType,
        is_first_purchase: isFirstPurchase,
      },
    } })

    // Failsafe: Reset send_to_production_analytics datalayer value to false
    TagManager.dataLayer({ dataLayer: {
      send_to_production_analytics: false,
    } })
  }
}

export default purchase
