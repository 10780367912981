import useTranslation from 'next-translate/useTranslation'
import { FlexboxGrid } from 'rsuite'
import { useState } from 'react'

import useIsOnMobile from '../services/useIsOnMobile'
import { ScreenSize, DIM_MID_COLOR } from '../utils/constants'
import SelectTile from './UI/SelectTile'
import CheckIcon from './Icons/CheckIcon'
import { getCookie } from '../utils/cookies'

import styles from '../styles/CustomerTypeSelection.module.less'

export type CustomerType = 'business' | 'work' | 'home'

interface CustomerTypeSelectionProps {
  onCustomerTypeSelect: (customerType: CustomerType) => void
}

const CUSTOMER_TYPES = [
  {
    type: 'business' as const,
    title: 'Business',
    subtitle: 'Prices excl VAT',
  },
  {
    type: 'home' as const,
    title: 'Private',
    subtitle: 'Prices incl VAT',
  },
]

const CustomerTypeSelection = ({
  onCustomerTypeSelect,
}: CustomerTypeSelectionProps) => {
  const [customerType, setCustomerType] = useState<CustomerType>(() => {
    const [, customerTypeValue] = getCookie('customerType') || []
    return customerTypeValue as CustomerType || 'business'
  })

  const { t } = useTranslation('common')
  const isOnMobile = useIsOnMobile(ScreenSize.md)

  const handleCustomerTypeSelect = (cType: CustomerType) => {
    setCustomerType(cType)
    onCustomerTypeSelect(cType)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h4>{t('How do you want to see prices?')}</h4>
      </div>

      <div className={styles.body}>
        <FlexboxGrid
          className={styles['customer-type-buttons-wrapper']}
          justify="space-between"
          align="middle"
        >
          {CUSTOMER_TYPES.map(({ type, title, subtitle }) => (
            <SelectTile
              key={type}
              active={customerType === type}
              onClick={() => handleCustomerTypeSelect(type)}
              alignCenter
              justifyCenter={!isOnMobile}
              direction={isOnMobile ? 'row' : 'column'}
            >
              { customerType === type ? (
                <CheckIcon
                  width={48}
                  height={48}
                  color={DIM_MID_COLOR}
                />
              ) : (
                <div className={styles.unselected} />
              )}
              <div className={styles['text-content']}>
                <h6>{t(subtitle)}</h6>
                <p className={styles.subtitle}>
                  {t(title)}
                </p>
              </div>
            </SelectTile>
          ))}
        </FlexboxGrid>
      </div>
    </div>
  )
}

export default CustomerTypeSelection
