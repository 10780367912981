import { Dispatch, FC, useEffect, useState, SetStateAction } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { FlexboxGrid, Form, InputGroup, InputPicker, Loader, Popover, Whisper } from 'rsuite'
import uniqueId from 'lodash/uniqueId'
import { captureException } from '@sentry/nextjs'
import HelpOutlineIcon from '@rsuite/icons/HelpOutline'

import { useAuth } from '../../../services/useAuth'
import { useAdminApi } from '../../../services/useApi'
import { deliveryServiceStub } from '../../../lib/resources/service'
import { isSupplier } from '../../../lib/supplier'
import { DeliveryService, ProductPrice, Rule, Service, ServiceList, Supplier } from '../../../utils/types/Product'
import ShippingDetailsRow from './ShippingDetailsRow'
import { ShippingCountries, SupplierShippingData } from '../../../utils/types/Profile'
import { ActiveCountryId, CountryData, DEFAULT_CURRENCY } from '../../../utils/constants'
import { getTaxRate } from '../../../lib/resources/price'
import { findCountry } from '../../../utils/countries'
import { isValidLocale } from '../../../utils/locales'

import styles from '../../../styles/Profile.module.less'

interface SupplierShippingDetailsProps {
  setDirty: Dispatch<SetStateAction<boolean>>
}

const defaultShippingDataRow: SupplierShippingData = {
  country: 'FI',
  limit: '500.00',
  fee: '0.00',
  days: '1',
  mov: '0.00',
  limit_vat: '500.00',
  fee_vat: '0.00',
  mov_vat: '0.00',
  rowId: uniqueId(),
}

const SupplierShippingDetails: FC<SupplierShippingDetailsProps> = (props) => {
  const { setDirty } = props

  const { t } = useTranslation('profile')
  const { user } = useAuth()
  const [currentShippingData, setCurrentShippingData] = useState<SupplierShippingData[]>([])

  const { getSingleResource, updateResource, getResource } = useAdminApi()

  const query = user ? `include=media,text,supplier/address&&filter[%3D%3D][supplier.siteid]=${user.siteid.toString()}` : ''

  // Fetch pricing rules to show if supplier has any country discount rules
  const { data: discountRules } = getResource<Rule>('rule', '')

  const { data: supplierInfo } = getSingleResource<Supplier>(isSupplier(user) ? 'supplier' : null, query)
  const serviceQuery = `include=price&filter[%3D%3D][service.code]=${supplierInfo?.['supplier.code']}`
  const {
    data: deliveryData,
    refresh,
    isLoading,
  } = getSingleResource<DeliveryService>(supplierInfo?.id ? 'service' : null, serviceQuery)
  const { data: servicePriceRelation } = getResource<ServiceList>(isLoading ? null : 'service/lists', 'filter[==][service.lists.domain]=price')

  const countriesList = CountryData.map((countryData) => (
    {
      value: (countryData.locale.toUpperCase() as ShippingCountries),
      label: t(`countries:${countryData.name}`),
    }
  ))

  const defaultServiceConfigObject: Service['service.config'] = {
    [defaultShippingDataRow.country]: {
      // @ts-ignore - ts complaining about the old data structure that doesn't exist anymore but
      // it is necessary to use it to access the old data
      limit: (deliveryData?.['service.config']?.[defaultShippingDataRow.country]?.limit || Number(defaultShippingDataRow.limit)),
      days: Number(defaultShippingDataRow.days),
      mov: deliveryData?.['service.config']?.[defaultShippingDataRow.country]?.mov || Number(defaultShippingDataRow.mov),
      limit_vat: (deliveryData?.['service.config']?.[defaultShippingDataRow.country]?.limit || Number(defaultShippingDataRow.limit)),
      mov_vat: deliveryData?.['service.config']?.[defaultShippingDataRow.country]?.mov || Number(defaultShippingDataRow.mov),
    },
  }

  const handleSetDirty = () => setDirty(true)

  const updateServiceData = async (
    newServiceConfigData: Partial<Service>,
    newPriceData: Partial<ProductPrice>,
  ) => {
    try {
      await updateResource<Service, Partial<Service>>('service', newServiceConfigData, `&id=${deliveryData?.id}`)
      await updateResource<ProductPrice, Partial<ProductPrice>>('price', newPriceData, `&id=${deliveryData?.price?.[0].id}`)
      refresh()
    } catch (error) {
      captureException(error)
      console.error(error)
    }
  }

  // Clean previous tab form changes indicator on component mount
  useEffect(() => {
    setDirty(false)
  }, [])

  useEffect(() => {
    // Check if the user data exists but no delivery service exists after fetching the service data
    if (user?.siteid && !isLoading && supplierInfo?.['supplier.code'] && !deliveryData?.id) {
      // A delivery service does not exist yet for this supplier, so we create one
      const service: DeliveryService = {
        ...deliveryServiceStub,
        'service.code': supplierInfo?.['supplier.code'],
        'service.label': `Shipping - ${supplierInfo?.['supplier.code']}`,
        'service.config': defaultServiceConfigObject,
      }
      const createService = async () => {
        // Create the service resource and link it to the supplier
        const result = await updateResource<Supplier, Partial<Supplier>>('supplier', {
          'supplier.id': supplierInfo.id,
          service: [service],
        }, `&id=${supplierInfo.id}`)
        const errors = result[2]
        if (Object.keys(errors).length === 0) {
          refresh()
        }
      }
      createService()
    }
  }, [deliveryData?.id, supplierInfo?.['supplier.code'], isLoading])

  // this useEffect exclusively handles the case where the supplier has already some data defined
  // that does not match the new data format, in that case update the 'service.config' and
  // 'service.price' fields with the new properties using the old data or some default data
  useEffect(() => {
    if (!isLoading && deliveryData) {
      // handle old data format, where 'service.config' have the 'limit' properties
      if (deliveryData && (!deliveryData['service.config'] || deliveryData['service.config']?.limit)) {
        const serviceConfigData = { 'service.config': defaultServiceConfigObject }
        const priceData = deliveryServiceStub.price![0]
        priceData.id = deliveryData.price?.[0].id || '' // use the old ID
        priceData['price.costs'] = deliveryData?.price?.[0]['price.costs'] || 0 // use the old costs value

        updateServiceData(serviceConfigData, priceData)
      }
    }
  }, [isLoading])

  useEffect(() => {
    // If the supplier already has delivery data, populate the rows array with it
    if (!isLoading && deliveryData?.id) {
      if (!deliveryData['service.config'] || !deliveryData.price || deliveryData['service.config']?.limit) return

      const serviceConfigData = deliveryData['service.config']
      const priceData = deliveryData.price

      setCurrentShippingData(() => Object.entries(serviceConfigData).map(
        ([country, configData]) => {
          const { limit, days, mov, limit_vat: limitVat, mov_vat: movVat } = configData
          const feeValue = priceData.find((priceObj) => priceObj['price.label'] === country)?.['price.costs'] ?? defaultShippingDataRow.fee
          const feeVatValue = priceData.find((priceObj) => priceObj['price.label'] === country && priceObj['price.type'] === 'vat-included')?.['price.costs'] ?? defaultShippingDataRow.fee

          // Lowercase country to correct format for getting the correct tax rate
          const countryId = country.toLowerCase()
          const taxRate = isValidLocale(countryId) ? getTaxRate(countryId) : getTaxRate(null)

          return {
            rowId: uniqueId(),
            country: country as ShippingCountries ?? defaultShippingDataRow.country,
            fee: feeValue ? Number(feeValue).toFixed(2) : defaultShippingDataRow.fee,
            limit: (limit != null) ? Number(limit).toFixed(2) : defaultShippingDataRow.limit,
            days: days ? String(days) : defaultShippingDataRow.days,
            mov: mov ? Number(mov).toFixed(2) : defaultShippingDataRow.mov,
            fee_vat: feeVatValue
              ? (Number(feeVatValue) * (1 + (taxRate / 100))).toFixed(2)
              : defaultShippingDataRow.fee,
            limit_vat: limitVat != null
              ? Number(limitVat).toFixed(2)
              : defaultShippingDataRow.limit,
            mov_vat: movVat
              ? Number(movVat).toFixed(2)
              : defaultShippingDataRow.mov,
          }
        },
      ))
    }
    // if the profile does not have a previous delivery data and the currentShippingData
    // is empty, populate it with a default value
    if (!isLoading && !deliveryData && !currentShippingData.length) {
      setCurrentShippingData([defaultShippingDataRow])
    }
  }, [isLoading, deliveryData?.id])

  const onNewCountrySelect = async (countryValue: ShippingCountries) => {
    if (!countryValue || !deliveryData?.price || !deliveryData?.['service.config']) return

    handleSetDirty()

    // update the data fields with the new country
    // set a new shipping data row with the selected country
    setCurrentShippingData((prevShippingData) => [...prevShippingData, {
      rowId: uniqueId(),
      country: countryValue,
      limit: defaultShippingDataRow.limit,
      fee: defaultShippingDataRow.fee,
      days: defaultShippingDataRow.days,
      mov: defaultShippingDataRow.mov,
      limit_vat: defaultShippingDataRow.limit,
      fee_vat: defaultShippingDataRow.fee,
      mov_vat: defaultShippingDataRow.mov,
    }])

    const currencyId = findCountry(countryValue as ActiveCountryId)?.currency

    const priceObjId = deliveryData?.price?.find((priceObj) => priceObj['price.label'] === countryValue)?.['price.id']
    let priceData = {}

    // prevent creating new price object for a given country if it already has such previous
    // object in the DB
    if (!priceObjId) {
      priceData = {
        price: [...deliveryData.price, {
          ...deliveryData?.price[0],
          id: '',
          'price.id': '',
          'price.costs': Number(defaultShippingDataRow.fee),
          'price.label': countryValue,
          'price.currencyid': currencyId || DEFAULT_CURRENCY,
        }],
      }
    }
    // then update the service with the new country data that will contain default values
    const dataToUpdate = {
      ...deliveryData,
      ...priceData,
      id: deliveryData?.id,
      'service.config': {
        ...deliveryData?.['service.config'],
        [countryValue]: {
          limit: Number(defaultShippingDataRow.limit),
          days: Number(defaultShippingDataRow.days),
          mov: Number(defaultShippingDataRow.mov),
          limit_vat: Number(defaultShippingDataRow.limit_vat),
          mov_vat: Number(defaultShippingDataRow.mov_vat),
        },
      },
    }

    try {
      await updateResource<Service, Partial<Service>>('service', dataToUpdate, `&id=${deliveryData?.id}`)
    } catch (error) {
      captureException(error)
      console.error(error)
    }
  }

  // create a new list by removing the already selected countries
  const countryListWithoutSelectedCountries = countriesList.filter(
    (countryData) => !currentShippingData.find(
      (rowData) => rowData.country === countryData.value,
    ),
  )

  // lists the currently selected countries in the shipping data array as a list
  // of strings for the row data country picker to disable, using disabledItemValues
  // (so there won't be a possibility for country duplication)
  const countriesToDisableList = currentShippingData.map((data) => data.country)

  if (!deliveryData || isLoading) {
    return (
      <Loader
        backdrop
        center
      />
    )
  }

  return (
    <div className={styles['supplier-shipping-details-container']}>
      <FlexboxGrid
        data-testid="supplier-shipping-data-labels"
        align="bottom"
        className={`${styles['labels-flex-row']} margin-bottom-spacer`}
      >
        <FlexboxGrid.Item colspan={3}>
          <Form.ControlLabel
            id="delivery-country"
            htmlFor="delivery-country"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          colspan={4}
          className={styles['double-column']}
        >
          <Form.ControlLabel
            id="supplier-mov"
            htmlFor="supplier-mov"
          >
            <b>
              {t('Minumum Order MOV *')}
            </b>
            <Whisper
              trigger="hover"
              placement="top"
              speaker={(
                <Popover>
                  <p>
                    Increments of 5 (0, 10, 50, ...)
                  </p>
                </Popover>
              )}
            >
              <HelpOutlineIcon className="margin-left-spacer" />
            </Whisper>
          </Form.ControlLabel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          colspan={4}
          className={styles['double-column']}
        >
          <Form.ControlLabel
            id="shipping-limit"
            htmlFor="shipping-limit"
          >
            <b>
              {t('Free shipping limit (FSL) from *')}
            </b>
            <Whisper
              trigger="hover"
              placement="top"
              speaker={(
                <Popover>
                  <p>
                    Increments of 5 (0, 10, 50, ...)
                  </p>
                </Popover>
              )}
            >
              <HelpOutlineIcon className="margin-left-spacer" />
            </Whisper>
          </Form.ControlLabel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          colspan={5}
          className={styles['double-column']}
        >
          <Form.ControlLabel
            id="shipping-fee"
            htmlFor="shipping-fee"
          >
            <b>
              {t('Shipping fee (if under FSL) *')}
            </b>
            <Whisper
              trigger="hover"
              placement="top"
              speaker={(
                <Popover>
                  <p>
                    end in x.45 or x.95, kr in 5 or 10
                  </p>
                </Popover>
              )}
            >
              <HelpOutlineIcon className="margin-left-spacer" />
            </Whisper>
          </Form.ControlLabel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4}>
          <Form.ControlLabel
            id="delivery-days"
            htmlFor="delivery-days"
          >
            <b>
              {t('Delivery time in business days *')}
            </b>
            <Whisper
              trigger="hover"
              placement="top"
              speaker={(
                <Popover>
                  <p>
                    Example: 1, 3, 5, 6
                  </p>
                </Popover>
              )}
            >
              <HelpOutlineIcon className="margin-left-spacer" />
            </Whisper>
          </Form.ControlLabel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid
        data-testid="supplier-shipping-data-sub-labels"
        align="bottom"
        className={`${styles['labels-flex-row']} margin-bottom-spacer`}
      >
        <FlexboxGrid.Item colspan={3} />
        <FlexboxGrid.Item colspan={2}>
          {t('With VAT')}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={2}>
          {t('No VAT')}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={2}>
          {t('With VAT')}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={2}>
          {t('No VAT')}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={2}>
          {t('With VAT')}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3}>
          {t('No VAT')}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4} />
      </FlexboxGrid>
      {currentShippingData.map((data) => (
        <ShippingDetailsRow
          key={data.rowId}
          rowData={data}
          setCurrentShippingData={setCurrentShippingData}
          refresh={refresh}
          servicePriceRelation={servicePriceRelation}
          supplierDeliveryData={deliveryData}
          countriesList={countriesList}
          countriesToDisable={countriesToDisableList}
          shouldDisplayTrashIcon={currentShippingData.length > 1}
          handleSetDirty={handleSetDirty}
          countryDiscount={discountRules}
        />
      ))}
      {countryListWithoutSelectedCountries.length > 0
      && currentShippingData.length < countriesList.length
      && (
        <FlexboxGrid.Item colspan={5}>
          <InputGroup inside>
            <Form.Control
              name="new-delivery-country-picker"
              data-testid="new-delivery-country-picker"
              placeholder={t('Choose delivery country')}
              value={null}
              accepter={InputPicker}
              data={countryListWithoutSelectedCountries}
              onChange={onNewCountrySelect}
              block
            />
          </InputGroup>
        </FlexboxGrid.Item>
      )}
    </div>
  )
}

export default SupplierShippingDetails
