import useTranslation from 'next-translate/useTranslation'
import { Dispatch, FC, SetStateAction } from 'react'
import { Button, FlexboxGrid } from 'rsuite'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { useAuth } from '../services/useAuth'
import usePrice from '../services/usePrice'
import useUrls from '../services/useUrls'
import { useCart } from './Cart/CartProvider'
import { getProductBaseSKU, getProductName } from '../lib/text'
import { getSalesUnit } from '../lib/salesUnit'
import { ScreenSize } from '../utils/constants'
import { getLargePreview, getMediumPreview, imageLoader } from '../utils/images'
import { formatPriceNumber } from '../utils/math'
import { Product, ProductQuantity } from '../utils/types/Product'
import { getIsImportedProduct, getPathName } from '../lib/product'
import DiscountIcon from './Icons/DiscountIcon'
import Avatar from './Avatar/Avatar'
import ProductEmptyImage from './SingleProduct/ProductEmptyImage'
import SupplierMovWarning from './Cart/SupplierMovWarning'
import { getRemainingMovCount } from '../lib/cart'
import { getSupplierDeliveryDetails, isFreeDelivery } from '../lib/service'
import { getCountryAndLocaleStrings } from '../utils/locales'
import { selectSupplierTotal } from './Cart/selectors'
import PriceRevealToolTip from './PriceRevealTooltip'
import useIsOnMobile from '../services/useIsOnMobile'
import { useCyModelWithProps } from '../services/useCyModel'

import styles from '../styles/ProductOrderInfo.module.less'

interface ProductOrderInfoProps {
  product: Product
  productQuantity: ProductQuantity[]
  totalQuantity: number
  isStockLimitReached: boolean
  title?: boolean
  isQuantityUnderMoq?: boolean
  setEditMode?: Dispatch<SetStateAction<boolean>>
  editMode?: boolean
  isOnShareCart?: boolean
  enableWarningBar?: boolean
  discountType?: 'bulk' | 'consumption' | 'custom'
  productIndex?: number
  supplierIndex?: number
}

const ProductOrderInfo: FC<ProductOrderInfoProps> = (props) => {
  const {
    product,
    productQuantity,
    totalQuantity,
    isStockLimitReached,
    title,
    isQuantityUnderMoq,
    setEditMode,
    editMode,
    isOnShareCart,
    enableWarningBar,
    discountType,
    productIndex = -1,
    supplierIndex = -1,
  } = props

  const { t } = useTranslation('modals')
  const { urlT } = useUrls()
  const { modifiedCart } = useCart()
  const { isVatIncluded } = useAuth()
  const { locale: countryAndLocale } = useRouter()
  const isOnMobile = useIsOnMobile(ScreenSize.md)

  const currentSupplier = modifiedCart?.supplierMappedProducts.find(
    (supp) => supp.supplierCode === product.supplier[0]['supplier.code'],
  )
  const supplierTotal = modifiedCart && currentSupplier
    ? selectSupplierTotal(modifiedCart, currentSupplier, isVatIncluded) || 0
    : 0

  const {
    default: {
      total: defaultPriceTotal,
    },
    final: {
      price: finalPrice,
      total: finalPriceTotal,
    },
    shippingWeighted: {
      final: {
        price: finalPriceWeighted,
      },
    },
    moq: productMoq,
    currency,
  } = usePrice(
    product,
    totalQuantity,
    {
      supplierTotalValue: supplierTotal,
      excludeQuantity: totalQuantity, // Exclude full quantity to not count twice in cart
    },
  )

  const { country } = getCountryAndLocaleStrings(countryAndLocale)

  const {
    minimumOrderValue: mov,
  } = getSupplierDeliveryDetails(currentSupplier?.supplierInfo, country, isVatIncluded)
  const remainingMov = getRemainingMovCount(supplierTotal, mov)
  const shouldShowWarning = enableWarningBar && supplierTotal < mov

  const media = product?.media?.map((file) => ({
    original: imageLoader(getLargePreview(file)),
    thumbnail: imageLoader(getMediumPreview(file)),
    originalHeight: 500,
    thumbnailHeight: 100,
  }))
  const productName = getProductName(product)
  const salesUnit = getSalesUnit(product)
  const totalDiscount = formatPriceNumber(defaultPriceTotal - finalPriceTotal)
  const supplier = product?.supplier[0]
  const hasFreeDelivery = product && isFreeDelivery(product, country, isVatIncluded, supplierTotal)

  const isImportedProduct = getIsImportedProduct(product)
  const productCode = isImportedProduct ? getProductBaseSKU(product) : product?.['product.code']

  useCyModelWithProps('SingleProduct', {
    orderInfo: {
      [productCode]: {
        defaultPriceTotal,
        finalPrice,
        finalPriceTotal,
        finalPriceWeighted,
      },
    },
  })

  return (
    <div
      data-testid={isQuantityUnderMoq ? 'under-moq-product-panel' : undefined}
      className={styles['order-info']}
    >
      {title && (
        <h5
          data-testid={`product-order-info-order-details-supplier-${supplierIndex}-product-${productIndex}`}
          className="margin-bottom-spacer-double"
        >
          {isStockLimitReached ? t('Inquiry details') : t('Order breakdown')}
          :
        </h5>
      )}
      <FlexboxGrid
        align={isQuantityUnderMoq ? 'middle' : undefined}
        className={styles['order-info-container']}
      >
        <FlexboxGrid.Item>
          <Link href={!isImportedProduct ? urlT(getPathName(product, t)) : urlT('/my-imported-products')}>
            <FlexboxGrid
              align="middle"
              className="flex-flow-row"
            >
              <FlexboxGrid.Item className={`${styles['product-logo']} flex-shrink-col-static ${isImportedProduct ? styles.imported : ''}`}>
                {media?.length ? (
                  <Avatar
                    size={isOnMobile ? 'md' : 'lg'}
                    imageSrc={media?.[0]?.thumbnail}
                    altName={media?.[0]?.original}
                  />
                )
                  : (
                    <ProductEmptyImage
                      height={50}
                      width={50}
                      isImportedProduct={isImportedProduct}
                    />
                  )}
              </FlexboxGrid.Item>
              {isOnMobile && (
                <FlexboxGrid.Item className="flex-shrink-col-dynamic">
                  <h5 className="margin-zero">{`${productCode}, ${productName}`}</h5>
                </FlexboxGrid.Item>
              )}
            </FlexboxGrid>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item className={`flex-grow-1 ${styles['product-info']}`}>
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item colspan={16}>
              {!isOnMobile && (
              <Link href={!isImportedProduct ? urlT(getPathName(product, t)) : urlT('/my-imported-products')}>
                <h5
                  data-testid={`product-order-info-supplier-${supplierIndex}-product-${productIndex}-name`}
                  className="margin-zero"
                >
                  {`${productCode}, ${productName}`}
                </h5>
              </Link>
              )}

              {isQuantityUnderMoq && (
                <p className="margin-top-spacer">
                  {`${t('products:Minimum order')}: ${productMoq} ${salesUnit}`}
                </p>
              )}

              {!isQuantityUnderMoq && productQuantity.length !== 1
                && productQuantity
                  .filter((ele) => ele.quantity !== 0 && ele.quantity !== '0')
                  .map((item) => (
                    <div
                      key={item.id}
                      className="margin-bottom-spacer-half"
                      data-testid={`product-order-info-supplier-${supplierIndex}-product-${productIndex}-variant-${item.id}-quantity`}
                    >
                      {`${item.label}: ${item.quantity} ${t(
                        `products:${salesUnit}`,
                        { count: Number(item.quantity) },
                      )}`}
                    </div>
                  ))}
            </FlexboxGrid.Item>

            {isQuantityUnderMoq && !isOnShareCart && !isOnMobile && (
              <Button
                data-testid={`product-order-info-supplier-${supplierIndex}-product-${productIndex}-modify-quantity-button`}
                disabled={editMode}
                onClick={() => setEditMode && setEditMode(true)}
                appearance="ghost"
                className={styles['modify-quantity-button']}
              >
                {t('cart:Modify quantity')}
              </Button>
            )}

            {!isQuantityUnderMoq && discountType === 'bulk' && (
              <FlexboxGrid.Item>
                <FlexboxGrid align="middle">
                  <DiscountIcon />
                  <b className={styles['discount-text']}>
                    {t('products:Bulk discounts')}
                  </b>
                </FlexboxGrid>
              </FlexboxGrid.Item>
            )}
          </FlexboxGrid>

          {!isQuantityUnderMoq && (
            <FlexboxGrid
              justify="space-between"
              className={isOnMobile ? 'margin-top-spacer flex-flow-row' : ''}
            >
              {isStockLimitReached ? (
                <div data-testid={`product-order-info-supplier-${supplierIndex}-product-${productIndex}-total-quantity`}>
                  <b>
                    {t('products:Total')}
                    :
                  </b>
                  {` ${totalQuantity} ${t(`products:${salesUnit}`, { count: Number(totalQuantity) })}`}
                </div>
              ) : (
                <>
                  <div data-testid={`product-order-info-supplier-${supplierIndex}-product-${productIndex}-price-calculation`}>
                    <PriceRevealToolTip
                      value={finalPriceWeighted}
                      supplier={supplier}
                      isFreeDelivery={hasFreeDelivery}
                      isImportedProduct={isImportedProduct}
                    />
                    {`${formatPriceNumber(finalPrice)} ${currency}`}
                    {' '}
                    &times;
                    {' '}
                    {totalQuantity}
                    {' '}
                    {t(`products:${salesUnit}`, { count: totalQuantity })}
                  </div>
                  <div className="flex-shrink-col-static">
                    <h6
                      className="margin-zero"
                      data-testid={`product-order-info-supplier-${supplierIndex}-product-${productIndex}-total-price`}
                    >
                      {`${formatPriceNumber(finalPriceTotal)} ${currency}`}
                    </h6>
                  </div>
                </>
              )}
            </FlexboxGrid>
          )}

          {!isQuantityUnderMoq && discountType && !isStockLimitReached && (
            <FlexboxGrid
              justify="space-between"
              className="margin-top-spacer"
            >
              <FlexboxGrid.Item>
                {t('cart:Discounts')}
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                data-testid={`product-order-info-supplier-${supplierIndex}-product-${productIndex}-total-discount`}
                className="h6"
              >
                {`- ${totalDiscount} ${currency}`}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {shouldShowWarning && (
        <div className="margin-top-spacer-double">
          <SupplierMovWarning
            currency={currency}
            mov={mov}
            remainingMov={remainingMov}
            supplierName={currentSupplier?.supplierInfo?.['supplier.label'] || ''}
            supplierCode={currentSupplier?.supplierCode || ''}
          />
        </div>
      )}
    </div>
  )
}

export default ProductOrderInfo
