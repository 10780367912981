import TagManager from 'react-gtm-module'

import { roundToTwoDecimals } from '../math'
import { emptyEcomDataLayer, getCartTotalDiscount, getCartTotalValue, getCartEcomEventItems } from './utils'
import { CartResponse, WithSuppliers } from '../types/Order'
import { Product } from '../types/Product'

interface BeginCheckoutParams {
  cartData: WithSuppliers<CartResponse>
  context?: 'quick_reorder'
  getLocalizedProductUrl: (product?: Product) => string
  isVatIncluded: boolean
}

const beginCheckout = ({ context, ...restParams }: BeginCheckoutParams) => {
  const items = getCartEcomEventItems(restParams)

  const { cartData } = restParams

  const { data } = cartData

  if (!data) {
    return
  }

  emptyEcomDataLayer() // First empty datalayer
  TagManager.dataLayer({ dataLayer: {
    event: 'begin_checkout',
    context: context || '',
    ecommerce: {
      affiliation: 'Droppe Marketplace',
      value: roundToTwoDecimals(data.attributes['order.price']),
      tax: roundToTwoDecimals(data.attributes['order.taxvalue']),
      shipping: roundToTwoDecimals(data.attributes['order.costs']),
      currency: data.attributes['order.currencyid'],
      coupon: 'none',
      totalValue: getCartTotalValue(cartData),
      discount: getCartTotalDiscount(cartData),
      items,
    },
  } })
}

export default beginCheckout
