import { FlexboxGrid } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import { FC, ReactNode, forwardRef, useImperativeHandle, useRef } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import SatisfactionGuarantee from '../../SatisfactionGuarantee'
import useInternalTraffic from '../../hooks/useInternalTraffic'
import InternalTrafficButton from '../../InternalTrafficButton'
import useUrls from '../../../services/useUrls'
import DroppeLogo from '../../Icons/DroppeLogo'
import OrderTimeIcon from '../../Icons/OrderTimeIcon'
import ClockIcon from '../../Icons/ClockIcon'
import CarIcon from '../../Icons/CarIcon'
import { ScreenSize, TOTAL_SATISFIED_CUSTOMERS } from '../../../utils/constants'
import useIsOnMobile from '../../../services/useIsOnMobile'
import { getCountryAndLocaleStrings } from '../../../utils/locales'
import { getCountryPhone } from '../../../utils/countries'
import AnimatedContentRotator from '../../common/AnimatedContentRotator'

import styles from '../../../styles/CheckoutHeader.module.less'

interface NavItemProps {
  children: ReactNode
  text?: string
}

const NavItem: FC<NavItemProps> = ({ children, text }) => (
  <FlexboxGrid.Item>
    {children}
    {text && <span className="margin-left-spacer">{text}</span>}
  </FlexboxGrid.Item>
)

const CheckoutHeader = forwardRef<HTMLElement>((_, ref) => {
  const { isInternalTraffic } = useInternalTraffic()
  const { urlT } = useUrls()
  const { locale: countryLangString } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryLangString)
  const isTablet = useIsOnMobile(ScreenSize.TABLET_HEADER_BREAKPOINT)
  const { t } = useTranslation('checkout')
  const headerRef = useRef<HTMLElement>(null)

  useImperativeHandle(ref, () => headerRef.current!, [headerRef])

  const valueProps = [
    <NavItem>
      <SatisfactionGuarantee variant="dark" />
    </NavItem>,
    <NavItem text={t('Quick delivery')}>
      <CarIcon />
    </NavItem>,
    <NavItem text={`${t('common:Customer service')} ${getCountryPhone(country)}`}>
      <ClockIcon />
    </NavItem>,
    <NavItem text={`${TOTAL_SATISFIED_CUSTOMERS}+ ${t('common:Satisfied customers')}`}>
      <OrderTimeIcon className={styles['order-time-icon']} />
    </NavItem>,
  ]

  return (
    <header
      ref={headerRef}
      className={`${styles['checkout-header']} hide-on-print`}
    >
      <FlexboxGrid
        align="middle"
        justify="space-between"
        className={`${styles['checkout-header-content']} max-width-lg`}
      >
        <Link href={urlT('/products')}>
          <DroppeLogo
            size="sd"
            alignment="left"
            className={styles['checkout-header-logo']}
          />
        </Link>

        <FlexboxGrid
          className={styles['value-props']}
          align="middle"
          justify="end"
        >
          {isTablet
            ? (
              <AnimatedContentRotator>
                {valueProps}
              </AnimatedContentRotator>
            )
            : valueProps}
          {isInternalTraffic && (
          <NavItem text="">
            <InternalTrafficButton isSimple />
          </NavItem>
          )}
        </FlexboxGrid>
      </FlexboxGrid>
    </header>
  )
})

CheckoutHeader.displayName = 'CheckoutHeader'

export default CheckoutHeader
