import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import fetcher from '../../utils/fetcher'
import useInitCart from './useInitCart'
import {
  CartAddressType,
  CartResponse,
  SingleOrderResponse,
  WithSuppliers,
} from '../../utils/types/Order'
import { isAddressInclude, isProductInclude } from '../../utils/types/guards/Order'
import { safeAwait } from '../../lib/responseHandlers'
import { getSupplierGroupByProducts } from '../../lib/cart'
import { Product, Supplier } from '../../utils/types/Product'
import useCyModel from '../../services/useCyModel'
import { CartContextType, CartState } from '../../utils/types/Cart'

export const CartContext = createContext<CartContextType>(
  {} as CartContextType,
)

interface CartProviderProps {
  children: React.ReactNode
}

export const CartProvider = ({ children }: CartProviderProps) => {
  const [cartState, setCartState] = useState<Omit<CartState, 'cart'>>({
    error: null,
    isLoading: false,
    isValidating: false,
    isDeletingProducts: false,
  })
  const [isMovModalVisible, toggleMovModal] = useState(false)

  const { isLoading: isCartLoading } = cartState

  const {
    cartResult,
    isLoading: isDataLoading,
    links,
    cartProducts,
    cartSuppliers,
    mutate,
    error,
    isCartValidating,
  } = useInitCart()

  const buildCart = (
    updatedCartResult: CartResponse, suppliers: Supplier[], products: Product[],
  ): WithSuppliers<CartResponse> => {
    const addToCartLinkObj = links[0]?.['basket/product']
    const addToCartLink = typeof addToCartLinkObj === 'string' ? addToCartLinkObj : addToCartLinkObj?.href

    return {
      ...updatedCartResult,
      included: updatedCartResult.included
        ?.map((item) => {
          if (isProductInclude(item)) {
            return {
              ...item,
              // Make sure id is string, as aimeos can be inconsistent with this
              id: item.id.toString(),
              addToCartLink,
              // Add full product data for each included product in the cart
              product: products.find((prod) => (
                // Find correct product based on code or variant code
                prod['product.code'] === item.attributes['order.product.prodcode']
                || prod.product?.find((variant) => (
                  variant['product.code'] === item.attributes['order.product.prodcode'])))),
            }
          }

          return item // No need to add data to services (shipping/payment)
        }),
      suppliers,
    }
  }

  const cart = useMemo(() => (
    cartResult ? buildCart(cartResult, cartSuppliers, cartProducts) : null
  ), [cartResult, cartSuppliers, cartProducts])

  const modifiedCart = cart ? getSupplierGroupByProducts(cart) : null

  const setCartLoading = useCallback((isLoading: boolean) => {
    setCartState((prevState) => ({
      ...prevState,
      isLoading,
    }))
  }, [])

  const addProduct: CartContextType['addProduct'] = useCallback(async (addUrl, productsToAdd) => {
    const [err] = await safeAwait<CartResponse>(
      fetcher(addUrl, 'POST', JSON.stringify({
        data: productsToAdd.filter((attr) => attr.quantity > 0).map((attrs) => ({
          attributes: {
            'product.id': attrs.id,
            quantity: attrs.quantity,
            supplier: attrs.supplierId,
            ...(attrs.variant && { variant: [attrs.variant] }),
          },
        })),
      })),
    )

    const respData = await mutate()
    const updatedCartData = buildCart(respData as CartResponse, cartSuppliers, cartProducts)

    return { data: updatedCartData, error: err }
  }, [mutate])

  const updateProduct: CartContextType['updateProduct'] = useCallback(async (updateUrl, dataToUpdate) => {
    const [err, data] = await safeAwait<CartResponse>(
      fetcher(updateUrl, 'PATCH', JSON.stringify({
        data: {
          attributes: {
            quantity: dataToUpdate.quantity,
          },
        },
      })),
    )

    const respData = await mutate(data)

    return { data: respData, error: err }
  }, [mutate])

  const deleteProduct: CartContextType['deleteProduct'] = useCallback(async (deleteUrls: string[]) => {
    setCartState((prevState) => ({ ...prevState, isDeletingProducts: true }))

    const [head, ...tail] = deleteUrls.filter((u) => !!u)
    const [err, data] = await tail.reduce(async (prevPromise, deleteUrl) => {
      await prevPromise
      return safeAwait(fetcher(deleteUrl, 'DELETE'))
    }, safeAwait<CartResponse>(fetcher(head, 'DELETE')))

    const respData = await mutate(data)

    setCartState((prevState) => ({ ...prevState, isDeletingProducts: false }))
    return { data: respData, error: err }
  }, [mutate])

  const addPayment: CartContextType['addPayment'] = useCallback(async (paymentUrl, paymentData, serviceId) => {
    const {
      businessId,
      invoiceType,
      paymentNotes,
      sendOrderConfirmation = true,
      paymentTerms = '',
    } = paymentData
    const [err, data] = await safeAwait<CartResponse>(
      fetcher(`${paymentUrl}&relatedid=payment`, 'POST', JSON.stringify({
        data: [{
          id: 'payment',
          attributes: {
            'service.id': serviceId,
            invoiceType,
            paymentNotes,
            taxNumber: businessId, // taxNumber key used in backend for business id
            sendOrderConfirmation: `${sendOrderConfirmation}`,
            paymentTerms,
          },
        }],
      })),
    )

    const respData = await mutate(data)

    return { data: respData, error: err }
  }, [mutate])

  const updateSingleAddress: CartContextType['updateSingleAddress'] = useCallback(
    async (addressType, addressData) => {
      setCartLoading(true)

      const updateUrl = cart?.included
        ?.filter(isAddressInclude)
        .find((address) => address.id === addressType)
        ?.links.self.href || ''

      const [err] = await safeAwait<CartResponse>(
        fetcher(
          updateUrl,
          'PATCH',
          JSON.stringify({
            data: [{
              id: addressType,
              attributes: addressData,
            }],
          }),
        ),
      )

      const respData = await mutate()
      setCartLoading(false)
      return { data: respData, error: err }
    }, [cart?.included, mutate, setCartLoading],
  )

  const updateAddress: CartContextType['updateAddress'] = useCallback(async (addressData) => {
    setCartLoading(true)

    const { billingAddress, deliveryAddress } = addressData

    const getUpdateUrl = (addressType: CartAddressType) => cart
      ?.included?.filter(isAddressInclude).find((address) => address.id === addressType)?.links.self.href || ''

    const updateUrls = {
      delivery: getUpdateUrl('delivery'),
      billing: getUpdateUrl('payment'),
    }

    const updateBillingAddress = () => fetcher(
      updateUrls.billing,
      'PATCH',
      JSON.stringify({
        data: [{
          id: 'payment', // Billing address
          attributes: billingAddress,
        }],
      }),
    )

    const updateDeliveryAddress = () => fetcher(
      updateUrls.delivery,
      'PATCH',
      JSON.stringify({
        data: [{
          id: 'delivery', // Delivery address
          attributes: deliveryAddress,
        }],
      }),
    )

    const [billingErr] = await safeAwait<CartResponse>(updateBillingAddress())
    const [deliveryError] = await safeAwait<CartResponse>(updateDeliveryAddress())

    const respData = await mutate()
    setCartLoading(false)

    return { data: respData, error: billingErr || deliveryError }
  }, [cart?.included, mutate, setCartLoading])

  const addSingleAddress: CartContextType['addSingleAddress'] = useCallback(async (
    addressUrl,
    addressType,
    addressData,
  ) => {
    setCartLoading(true)

    const [err] = await safeAwait<CartResponse>(
      fetcher(
        addressUrl,
        'POST',
        JSON.stringify({
          data: [{
            id: addressType,
            attributes: addressData,
          }],
        }),
      ),
    )

    const respData = await mutate()
    setCartLoading(false)

    return { data: respData, error: err }
  }, [mutate, setCartLoading])

  const addAddress: CartContextType['addAddress'] = useCallback(async (
    addUrl,
    { billingAddress, deliveryAddress },
  ) => {
    setCartLoading(true)

    const payload = {
      data: [{
        id: 'payment', // Billing address
        attributes: billingAddress,
      }, {
        id: 'delivery', // Delivery address
        attributes: deliveryAddress,
      }],
    }

    const [err] = await safeAwait<CartResponse>(
      fetcher(
        addUrl,
        'POST',
        JSON.stringify(payload),
      ),
    )

    const respData = await mutate()
    setCartLoading(false)

    return { data: respData, error: err }
  }, [mutate, setCartLoading])

  const deleteAddresses: CartContextType['deleteAddresses'] = useCallback(async () => {
    setCartLoading(true)

    const deleteUrls = cart?.included
      ?.filter(isAddressInclude)
      .map((address) => address.links.self.href) || []

    deleteUrls.slice(1).reduce(async (prevPromise, deleteUrl) => {
      await prevPromise
      return safeAwait(fetcher(deleteUrl, 'DELETE'))
    }, safeAwait<CartResponse>(fetcher(deleteUrls[0], 'DELETE')))

    const respData = await mutate()
    setCartLoading(false)

    return { data: respData, error: null }
  }, [cart?.included, mutate, setCartLoading])

  const updateCart: CartContextType['updateCart'] = useCallback(async (attributes) => {
    setCartLoading(true)
    const url = cart?.links?.self?.href

    if (!url) {
      setCartLoading(false)
      return { data: null, error: new Error('No cart loaded') }
    }

    const [err, data] = await safeAwait<CartResponse>(
      fetcher(url, 'PATCH', JSON.stringify({
        data: {
          attributes,
        },
      })),
    )

    const respData = await mutate(data)
    setCartLoading(false)

    return { data: respData, error: err }
  }, [cart?.links?.self?.href, mutate, setCartLoading])

  const clearCart: CartContextType['clearCart'] = useCallback(async () => {
    setCartLoading(true)

    const url = cart?.links?.self?.href

    if (!url) {
      setCartLoading(false)
      return { data: null, error: new Error('No cart loaded') }
    }

    const [err, data] = await safeAwait<CartResponse>(fetcher(url, 'DELETE'))
    const respData = await mutate(data)

    setCartLoading(false)
    return { data: respData, error: err }
  }, [cart?.links?.self?.href, mutate, setCartLoading])

  const createOrder: CartContextType['createOrder'] = useCallback(async () => {
    const url = cart?.links?.self?.href

    if (!url) {
      return { data: null, error: new Error('No cart loaded') }
    }

    const [err, data] = await safeAwait<CartResponse>(fetcher(url, 'POST'))

    if (err) {
      return { data: null, error: err }
    }

    await mutate(data)

    if (!data) {
      return { data: null, error: null }
    }

    const [orderErr, orderData = null] = await safeAwait<SingleOrderResponse>(
      fetcher(data.links.order.href, 'POST', JSON.stringify({
        data: {
          attributes: {
            'order.id': data.data.attributes['order.id'],
          },
        },
      })),
    )

    return { data: orderData, error: orderErr }
  }, [cart?.links?.self?.href, mutate])

  // Optimistically augment 'modifiedCart' data by passing suppliers and products as params
  // to avoid latency in useInitCart product request. If not passed, use existing cart data.
  const refresh = async (suppliers?: Supplier[], products?: Product[]) => {
    const cartData = await mutate()

    if (!cartData) {
      return undefined
    }

    // Remove duplicates from supplier and product arrays
    const filterById = <T extends Product | Supplier>(items: T[]): T[] => Array.from(
      items.reduce((map, item) => map.set(item.id, item),
        new Map<string, T>())
        .values(),
    )

    // In case fresh cartSuppliers or cartProducts aren't available yet, pass them
    // optimistically via function params
    const supplierArray = filterById([...(suppliers || []), ...cartSuppliers])
    const productsArray = filterById([...(products || []), ...cartProducts])

    const updatedCartData = buildCart(cartData, [...supplierArray], [...productsArray])
    const updatedModifiedCart = getSupplierGroupByProducts(updatedCartData)

    return {
      data: updatedCartData,
      modifiedCart: updatedModifiedCart,
    }
  }

  const addCoupon: CartContextType['addCoupon'] = useCallback(async (code) => {
    const link = cartResult?.links['basket.coupon']
    const url = typeof link === 'string' ? link : link?.href
    if (!url) {
      return { data: null, errors: 'No coupon link found' }
    }
    const [err, data] = await safeAwait<CartResponse>(
      fetcher(url, 'POST', JSON.stringify({
        data: {
          id: code,
        },
      })),
    )

    if (data) {
      const respData = await mutate(data)
      return { data: respData, error: err }
    }

    return { data: null, errors: 'No response data' }
  }, [mutate, cartResult])

  const deleteCoupon: CartContextType['deleteCoupon'] = useCallback(async (url) => {
    const [err, data] = await safeAwait<CartResponse>(fetcher(url, 'DELETE'))

    const respData = await mutate(data)

    return { data: respData, error: err }
  }, [mutate, cartResult])

  // For value memoization to stop unnecessary rerenders
  const value = useMemo(
    () => (
      {
        cartState: {
          ...cartState,
          cart,
          error,
          isLoading:
            isCartLoading
            || isDataLoading,
          isValidating: isCartValidating,
        },
        modifiedCart,
        isMovModalVisible,
        toggleMovModal,
        refresh,
        addProduct,
        updateProduct,
        deleteProduct,
        addPayment,
        addSingleAddress,
        addAddress,
        updateSingleAddress,
        updateAddress,
        deleteAddresses,
        updateCart,
        clearCart,
        createOrder,
        addCoupon,
        deleteCoupon,
      }
    ),
    [
      cart,
      cartState,
      error,
      isCartLoading,
      isDataLoading,
      isCartValidating,
      modifiedCart,
      isMovModalVisible,
    ],
  )

  useCyModel('Cart', { ...value })

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => useContext(CartContext)
