import { ReactNode, useState } from 'react'
import { Animation } from 'rsuite'

interface AnimatedContentRotatorProps {
  /**
   * Array of components to rotate through
   */
  children: ReactNode[]
  /**
   * Duration of the animation in milliseconds
   */
  animationDuration?: number
  /**
   * Time to wait before starting to exit animation (ms)
   */
  displayDuration?: number
  /**
   * Time to wait before showing the next item (ms)
   */
  transitionDuration?: number
  /**
   * Placement for the slide animation
   */
  enterPlacement?: 'top' | 'right' | 'bottom' | 'left'
  /**
   * Placement for the exit animation (opposite of enter usually)
   */
  exitPlacement?: 'top' | 'right' | 'bottom' | 'left'
  /**
   * Optional className for the container
   */
  className?: string
}

/**
 * A component that rotates through an array of components with animation
 */
const AnimatedContentRotator: React.FC<AnimatedContentRotatorProps> = ({
  children,
  animationDuration = 2000,
  displayDuration = 2000,
  transitionDuration = 1000,
  enterPlacement = 'bottom',
  exitPlacement = 'top',
  className = '',
}) => {
  const [showAnimation, setShowAnimation] = useState(true)
  const [contentIndex, setContentIndex] = useState(0)

  if (children.length === 0) {
    return null
  }

  const currentContent = children[contentIndex]

  const handleAnimationEnter = () => setTimeout(() => {
    // After display duration, set the show state to false to trigger exit animation
    setShowAnimation(false)
  }, displayDuration)

  const handleAnimationExit = () => setTimeout(() => {
    setShowAnimation(true)
    setContentIndex((prevIndex) => (prevIndex + 1) % children.length)
  }, transitionDuration)

  return (
    <div className={className}>
      <Animation.Slide
        transitionAppear
        placement={showAnimation ? enterPlacement : exitPlacement}
        timeout={animationDuration}
        in={showAnimation}
        onEntered={handleAnimationEnter}
        onExiting={handleAnimationExit}
      >
        {(slideProps, slideRef) => (
          <div
            {...slideProps}
            ref={slideRef}
          >
            {currentContent}
          </div>
        )}
      </Animation.Slide>
    </div>
  )
}

export default AnimatedContentRotator
