import { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { useApi } from '../../services/useApi'
import { Customer, CustomerRelationList, Product } from '../../utils/types/Product'
import { useAuth } from '../../services/useAuth'
import useLoginModal from '../../services/useLoginModal'
import { ALERT_DURATION } from '../../utils/constants'
import { isDefined } from '../../utils/types/misc'
import { CustomerListIds, isFavoriteProduct } from '../../lib/savedProducts'
import useUserAnalyticsEvents from '../../services/analytics/useUserAnalyticsEvents'
import useGetAnalyticsPageLocation from '../../services/analytics/useGetAnalyticsPageLocation'
import { AnalyticsContext, AnalyticsLocation } from '../../utils/types/analytics'

type UseSavedProductActions = {
  handleSaveProductToggle: (analyticsLocation?: AnalyticsLocation | AnalyticsContext | '') => Promise<void>
  errorMessage: string
  isProductSaved: boolean
  isRefreshing: boolean
  savedProductsCount: number
}

const useSavedProductActions = (
  product?: Product,
  selectedCustomer?: Customer,
): UseSavedProductActions => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isProductSaved, setIsProductSaved] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const { t } = useTranslation('products')

  const { getResource, deleteResource, createResource } = useApi()
  const { openLoginModal } = useLoginModal()
  const { user } = useAuth()

  const {
    saveProductEvent,
    unsaveProductEvent,
  } = useUserAnalyticsEvents()

  const analyticsLocationPage = useGetAnalyticsPageLocation()

  const productId = product?.id

  const {
    data,
    refresh,
  } = getResource<CustomerRelationList>(user ? 'customer' : null, '&related=relationships&include=product,customer/customer', { shouldUseCache: false })

  const savedProductsCount = data.filter(isFavoriteProduct).length

  const customerRelationsList: CustomerListIds[] = selectedCustomer
    ?.product
    ?.filter((prod) => prod['customer.lists.type'] === 'favorite')
    ?.filter((prod) => isDefined(prod.id))
    .map((prod) => ({
      'customer.lists.refid': prod.id || '', // Will always be defined since filtered above
      'customer.lists.id': prod['customer.lists.id'] || '',
      'customer.lists.type': prod['customer.lists.type'] || '',
      'customer.lists.parentid': prod['customer.lists.parentid'] || '',
    })) || []

  const relationExists = customerRelationsList.concat(data).filter(isFavoriteProduct)
    .some((customerRelation) => customerRelation['customer.lists.refid'] === productId)

  const addSavedProduct = async () => {
    // Only save product if relation doesn't exist
    if (!relationExists) {
      const userRelationData = [{
        'customer.lists.refid': productId || '',
        'customer.lists.domain': 'product',
        'customer.lists.type': 'favorite',
        'customer.lists.datestart': null,
        'customer.lists.dateend': null,
        'customer.lists.position': 0,
        'customer.lists.status': 1,
        'customer.lists.parentid': '',
        'customer.lists.editor': '',
      }]

      try {
        setIsProductSaved(true)
        setIsRefreshing(true)

        const [,,response] = await createResource<Customer, CustomerRelationList>('customer', userRelationData, '&related=relationships')

        const respError = response.customer
          ?.find((customer) => customer.errors)?.errors?.[0]?.title
            || response?.customer?.[0]?.exception
            || ''
        if (respError) {
          throw new Error(respError)
        }

        // Refresh data to get new customer relation id
        refresh()
      } catch (e) {
        setIsProductSaved(false)
        setIsRefreshing(false)
        setErrorMessage(t('Oh no, failed to save product'))
        setTimeout(() => setErrorMessage(''), ALERT_DURATION)
        console.error(e)
      }
    }
  }

  const deleteSavedProduct = async () => {
    // Only remove saved product if relation exists
    if (relationExists) {
      setIsProductSaved(false)
      setIsRefreshing(true)
      const customerRelationId = data.find((customerRelation) => customerRelation['customer.lists.refid'] === productId)?.['customer.lists.id'] || ''

      try {
        const isDeletionSuccessful = await deleteResource('customer', `&related=relationships&include=product,customer/customer&relatedid=${customerRelationId}`)

        if (!isDeletionSuccessful) {
          throw new Error('Error deleting saved product')
        }

        refresh()
      } catch (e) {
        setIsProductSaved(true)
        setIsRefreshing(false)
        setErrorMessage(t('Oh no, failed to remove product'))
        setTimeout(() => setErrorMessage(''), ALERT_DURATION)
        console.error(e)
      }
    }
  }

  const handleSaveProductToggle = async (analyticsLocation?: AnalyticsLocation | AnalyticsContext | '') => {
    if (!user) {
      openLoginModal(analyticsLocation || '')
      return
    }

    if (!isRefreshing && product) {
      if (isProductSaved) {
        await deleteSavedProduct()
        unsaveProductEvent(product, analyticsLocation || analyticsLocationPage)
      } else {
        await addSavedProduct()
        saveProductEvent(product, analyticsLocation || analyticsLocationPage)
      }
    }
  }

  // Update refresh status when data has refreshed
  useEffect(() => {
    setIsRefreshing(false)

    if (data && !isRefreshing) {
      const isSaved = customerRelationsList.concat(data).filter(isFavoriteProduct)
        .some((customerRelation) => customerRelation['customer.lists.refid'] === productId)

      setIsProductSaved(isSaved)
    }
  }, [data.length, customerRelationsList.length])

  return {
    isRefreshing,
    isProductSaved,
    errorMessage,
    handleSaveProductToggle,
    savedProductsCount,
  }
}

export default useSavedProductActions
