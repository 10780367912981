import { Locale } from '../external'
import { CartSupplierProducts } from '../utils/types/Cart'
import { CurrencySymbol, DiscountProduct, Product } from '../utils/types/Product'
import { Currency } from '../utils/constants'
import { selectSupplierTotal } from '../components/Cart/selectors'
import { libraryFunctions as discountLibraryFunctions } from './resources/discount'
import { getSupplierDeliveryDetails, isFreeDelivery } from './service'
import { isProductInclude } from '../utils/types/guards/Order'
import { isDefined } from '../utils/types/misc'
import { calculatePriceWithTax, CalculateTakeRate, libraryFunctions } from './resources/price'

const {
  getDefaultPrice,
  getPrice,
  getProductPrice,
  getReferencePrice,
} = libraryFunctions
const { getCurrentUpcomingDiscount } = discountLibraryFunctions

export interface Prices {
  default: {
    price: number
    total: number
    reference: number
  }
  final: {
    price: number
    total: number
    reference: number
  }
  shippingWeighted: {
    default: {
      price: number
      total: number
      reference: number
    }
    final: {
      price: number
      total: number
      reference: number
    }
  }
  cost: {
    price: number
  }
  discount: {
    discounts: DiscountProduct[]
    current: DiscountProduct | undefined
    upcoming: DiscountProduct | undefined
    lowest: DiscountProduct | undefined
  }
  moq: number
  mov: number
  currency: CurrencySymbol
  hasDiscounts: boolean
  hasCustomPrices: boolean
}

interface PriceOptions {
  country: Locale
  modifiedCart?: CartSupplierProducts | null
  supplierTotalValue?: number
  calcTakeRate?: CalculateTakeRate
  takeRateExcludeAmount?: number
  takeRateExcludeQuantity?: number
  isVatIncluded?: boolean
}

const takeRateNoOp: CalculateTakeRate = (p) => ({
  product: p,
  prices: p.price,
})
/**
 * Getter for all the different kinds of prices of a product.
 * This or the hook should be the entry to all price data for a product used in components
 * @param productWithoutTakeRate product object
 * @param selectedQuantity quantity for calculating total prices, MOQs, discounts etc.
 * @param options extra context needed to calculate consumption take rate, weighted prices etc.
 */
export const getPrices = (productWithoutTakeRate: Product, selectedQuantity: number, options: PriceOptions = { country: 'fi' }): Prices => {
  const {
    country,
    modifiedCart,
    supplierTotalValue,
    takeRateExcludeAmount = 0,
    calcTakeRate = takeRateNoOp,
    takeRateExcludeQuantity = 0,
    isVatIncluded = false,
  } = options

  // Add tax or just pass value through if tex exclusive
  const addTax = isVatIncluded
    ? (value: number) => calculatePriceWithTax(value, country)
    : (value: number) => value

  // Default prices - not affected by takeRate
  const defaultPriceObject = getDefaultPrice(productWithoutTakeRate.price)
  const defaultPriceWithoutTax = Number(defaultPriceObject?.['price.value']) + Number(defaultPriceObject?.['price.rebate'])
  const defaultPrice = addTax(defaultPriceWithoutTax)
  const defaultPriceTotal = defaultPrice * selectedQuantity
  const defaultReferencePrice = getReferencePrice(productWithoutTakeRate, defaultPrice)

  const moq = Number(defaultPriceObject?.['price.quantity']) || 1
  const mov = defaultPrice * moq
  const currency = defaultPriceObject?.['price.currencyid'] ? Currency[defaultPriceObject['price.currencyid']] : '€'

  const {
    product,
  } = calcTakeRate(
    productWithoutTakeRate,
    selectedQuantity,
    takeRateExcludeAmount + (defaultPriceWithoutTax * takeRateExcludeQuantity),
  )

  const sortedPrices = [...product.price.filter((price) => price['price.type'] === 'default').sort((a, b) => b['price.quantity'] - a['price.quantity'])]

  // Final prices
  const lowestAvailablePrice = sortedPrices.find((price) => price['price.quantity'] <= selectedQuantity) || sortedPrices.at(-1)
  const finalPrice = addTax(Number(lowestAvailablePrice?.['price.value']))
  const finalPriceTotal = finalPrice * selectedQuantity
  const referencePrice = getReferencePrice(product, finalPrice)

  // Weighted prices
  const supplier = product?.supplier?.[0]
  const cartSupplier = modifiedCart?.supplierMappedProducts.find(
    (supp) => supp.supplierCode === supplier?.['supplier.code'],
  )

  // Use cart value unless supplierTotalValue is provided
  const supplierTotal = supplierTotalValue || (modifiedCart && cartSupplier
    ? selectSupplierTotal(modifiedCart, cartSupplier, isVatIncluded) || 0
    : 0)

  // Discounts
  const {
    currentDiscount: current,
    upcomingDiscount: upcoming,
    productDiscounts: discounts,
  } = getCurrentUpcomingDiscount(product, selectedQuantity)
  const lowest = discounts.at(-1)

  const { deliveryCost } = product && isFreeDelivery(product, country, isVatIncluded, supplierTotal)
    ? { deliveryCost: 0 }
    : getSupplierDeliveryDetails(supplier, country)
  const productPrice = getProductPrice(product)
  const cartProductPrice = Number(modifiedCart
    ?.included
    ?.filter(isProductInclude)
    ?.find((cartProduct) => (
      cartProduct.attributes['order.product.parentproductid']
      || cartProduct.attributes['order.product.productid']) === product?.id)
    ?.attributes['order.product.price'])
  const discountedPrice = cartProductPrice || Number(current?.['price.value'] || productPrice)

  // The weighted prices work either against cart total or a manually passed total
  // The manually passed value "supplierTotalValue" takes precedent if passed and allows
  // manually tweaking what is included in the total. It's currently used for catalogs
  const productRatio = supplierTotalValue
    ? productPrice / supplierTotalValue
    : (cartProductPrice || productPrice) / supplierTotal
  const weightedDefaultPrice = supplierTotal > 0
    ? productPrice + (deliveryCost * productRatio)
    : productPrice
  const weightedFinalPrice = supplierTotal > 0
    ? discountedPrice + (deliveryCost * productRatio)
    : discountedPrice

  const weightedDefaultReferencePrice = getReferencePrice(product, weightedDefaultPrice)
  const weightedFinalReferencePrice = getReferencePrice(product, weightedFinalPrice)

  const hasCustomPrices = isDefined(product.price.find((price) => price['price.label'].includes('custom_discount')))

  const costPrice = Number(getPrice(product.price, 'cost', 'EUR')?.['price.value']) // Cost is always in EUR

  return {
    default: {
      price: defaultPrice,
      total: defaultPriceTotal,
      reference: defaultReferencePrice,
    },
    final: {
      price: finalPrice,
      total: finalPriceTotal,
      reference: referencePrice,
    },
    shippingWeighted: {
      default: {
        price: weightedDefaultPrice,
        total: weightedDefaultPrice * (selectedQuantity || 1),
        reference: weightedDefaultReferencePrice,
      },
      final: {
        price: weightedFinalPrice,
        total: weightedFinalPrice * (selectedQuantity || 1),
        reference: weightedFinalReferencePrice,
      },
    },
    discount: {
      discounts,
      current,
      upcoming,
      lowest,
    },
    cost: {
      price: costPrice,
    },
    moq,
    mov,
    currency,
    hasDiscounts: discounts.length > 0,
    hasCustomPrices,
  }
}
