import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { FlexboxGrid, IconButton, Input, InputGroup, Notification, Popover, toaster, Tooltip, Whisper } from 'rsuite'
import { captureException } from '@sentry/nextjs'
import { FC } from 'react'

import useMounted from '../services/useMounted'
import LinkIcon from './Icons/LinkIcon'
import useInternalTraffic from './hooks/useInternalTraffic'
import useIsOnMobile from '../services/useIsOnMobile'
import { ScreenSize } from '../utils/constants'

import styles from '../styles/InternalTrafficButton.module.less'

interface InternalTrafficButtonProps {
  isSimple?: boolean
}

const InternalTrafficButton: FC<InternalTrafficButtonProps> = ({ isSimple = false }) => {
  const { locale, asPath } = useRouter()
  const { email, encryptedEmail } = useInternalTraffic()
  const { t } = useTranslation('cart')

  const isMounted = useMounted()
  const isOnMobile = useIsOnMobile(ScreenSize.lg)

  const baseUrl = isMounted ? window.location.hostname : ''
  const paramsExist = asPath.includes('?')
  const utmCampaignExists = asPath.includes('utm_campaign')
  const queryDivider = paramsExist ? '&' : '?'
  const utmParams = !utmCampaignExists ? `${queryDivider}utm_campaign=share_${encryptedEmail}` : ''
  const url = `https://${baseUrl}/${locale}${asPath}${utmParams}`

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(url)
      toaster.push((
        <Notification
          type="success"
          header={<b>{t('Link copied')}</b>}
          className={styles['success-notification']}
          duration={2000}
        >
          {t('You can now share the link')}
        </Notification>
      ), { placement: 'bottomCenter' })
    } catch (error) {
      captureException(error)
      console.error(error)
    }
  }

  const copyLinkPopover = (
    <Popover
      className={`${styles['internal-link-button']}
        ${styles['copy-link-popover']}
        ${styles['popover-center']}`}
      arrow={false}
    >
      <FlexboxGrid
        className={styles['internal-link-button-user']}
        justify="space-between"
        align="middle"
      >
        <FlexboxGrid.Item><h5>User</h5></FlexboxGrid.Item>
        <FlexboxGrid.Item className={styles['internal-link-button-user-email']}>{email}</FlexboxGrid.Item>
      </FlexboxGrid>
      <InputGroup>
        <Input
          readOnly
          value={url}
          data-testid="internal-link-button-url"
        />
        <InputGroup.Button
          onClick={copyLink}
          data-testid="internal-link-button-copy"
        >
          {t('Copy')}
        </InputGroup.Button>
      </InputGroup>
    </Popover>
  )

  const emailHover = (
    <Tooltip className={`${styles['internal-link-button']} ${styles['email-tooltip']}`}>
      {email}
    </Tooltip>
  )

  if (isOnMobile || isSimple) {
    return (
      <div className={styles['internal-link-button']}>
        <Whisper
          placement="bottom"
          controlId="control-id-click"
          trigger="click"
          speaker={copyLinkPopover}
        >
          <IconButton
            className={styles['internal-cookie-icon-button']}
            icon={<LinkIcon />}
            placement="right"
          />
        </Whisper>
      </div>
    )
  }

  return (
    <div className={styles['internal-link-button']}>
      <Whisper
        placement="bottomEnd"
        controlId="control-id-click"
        trigger="click"
        speaker={copyLinkPopover}
      >
        {/* Needs an additional div for nested Whispers to work */}
        <div>
          <Whisper
            placement="bottom"
            controlId="control-id-hover"
            trigger="hover"
            speaker={emailHover}
          >
            <IconButton
              icon={<LinkIcon />}
              placement="right"
            >
              <span className={styles['internal-link-button-email']}>
                {email}
              </span>
            </IconButton>
          </Whisper>
        </div>
      </Whisper>
    </div>
  )
}

export default InternalTrafficButton
