import { ItemDataType } from 'rsuite/esm/@types/common'

import { DataItemType } from '../../../utils/types/rsuite'

type PropertyPickerInstance = {
  value: string[] | string
  options: DataItemType[] | ItemDataType<string>[]
  pickAttributesByCode: (value: string[] | null) => void
}

interface PropertyPickerModel {
  [key: string]: PropertyPickerInstance
}

class PropertyPicker implements PropertyPickerModel {
  [key: string]: PropertyPickerInstance

  constructor(model: PropertyPickerModel) {
    Object.keys(model).forEach((key) => {
      this[key] = model[key]
    })
  }
}

export default PropertyPicker
