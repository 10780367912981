import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import login, { LoginEventParams } from '../../utils/analytics/loginEvent'
import logout from '../../utils/analytics/logoutEvent'
import setDatalayerUserId from '../../utils/analytics/setDatalayerUserId'
import saveProductToggle from '../../utils/analytics/saveProductToggle'
import signUp, { SignUpEventParams } from '../../utils/analytics/signUpEvent'
import viewLoginModal from '../../utils/analytics/viewLoginModal'
import viewRegisterModal from '../../utils/analytics/viewRegisterModal'
import { AnalyticsContext, AnalyticsLocation, LoginModalEvents, LoginModalEventsValue } from '../../utils/types/analytics'
import { getBasePathWithLocale } from '../../utils/urls'
import useTrackingContext from '../trackingContext/useTrackingContext'
import { CountryAndLocale } from '../../external'
import useUrls from '../useUrls'
import { getPathName } from '../../lib/product'
import forgotPassword from '../../utils/analytics/forgotPassword'
import submitPasswordReset from '../../utils/analytics/submitPasswordReset'
import { Product } from '../../utils/types/Product'
import beginBuyerOnboardingEvent from '../../utils/analytics/beginBuyerOnboarding'
import continueBuyerOnboardingEvent from '../../utils/analytics/continueBuyerOnboarding'
import addPersonalInfoBuyerOnboardingEvent from '../../utils/analytics/addPersonalInfoBuyerOnboarding'
import addPreferencesBuyerOnboardingEvent from '../../utils/analytics/addPreferencesBuyerOnboarding'
import addCompanyInfoBuyerOnboardingEvent from '../../utils/analytics/addCompanyInfoBuyerOnboarding'
import submitBuyerOnboardingEvent from '../../utils/analytics/submitBuyerOnboarding'
import updateAccountDetailsEvent from '../../utils/analytics/updateAccountDetails'
import updateDeliveryDetailsEvent from '../../utils/analytics/updateDeliveryDetails'
import updateBillingDetailsEvent from '../../utils/analytics/updateBillingDetails'
import { hasCookie } from '../../utils/cookies'
import { INTERNAL_TRAFFIC_COOKIE_NAME } from '../../utils/constants'
import { isProduction } from '../../utils/environment'
import useGetAnalyticsPageLocation from './useGetAnalyticsPageLocation'

const useUserAnalyticsEvents = () => {
  const { state: globalTrackingObject } = useTrackingContext()
  const { locale: countryAndLocaleString } = useRouter()
  const { urlT } = useUrls()
  const { t } = useTranslation('url')
  const analyticsPageLocation = useGetAnalyticsPageLocation()

  // Util function to get login modal context value from global tracking object
  const getContext = (eventName: LoginModalEventsValue) => globalTrackingObject[eventName]?.context || analyticsPageLocation || ''

  // Modal events
  const viewLoginModalEvent = (analyticsContext?: AnalyticsContext) => {
    const context = analyticsContext || getContext(LoginModalEvents.ViewLoginModal) || 'direct_login_modal'
    viewLoginModal(context)
  }

  const viewRegisterModalEvent = (analyticsContext?: AnalyticsContext) => {
    const context = analyticsContext || getContext(LoginModalEvents.ViewRegisterModal) || 'direct_register_modal'
    viewRegisterModal(context)
  }

  const loginEvent = ({ method, context: analyticsContext, email, userType }: LoginEventParams) => {
    const context = analyticsContext || getContext(LoginModalEvents.Login)
    login({ context, method, email, userType })
  }

  const logoutEvent = (analyticsContext?: AnalyticsContext) => {
    const context = analyticsContext || getContext(LoginModalEvents.Logout)
    logout(context)
  }

  const signUpEvent = ({
    method,
    context: analyticsContext,
    email,
    userType,
  }: SignUpEventParams) => {
    const context = analyticsContext || getContext(LoginModalEvents.SignUp)
    const isInternalTraffic = hasCookie(INTERNAL_TRAFFIC_COOKIE_NAME)

    const sendToProductionAnalytics = isInternalTraffic && isProduction
      // eslint-disable-next-line no-alert
      ? window.confirm("Are you creating an account for a customer? If yes click 'OK', otherwise click 'CANCEL'. This choice will only affect analytics.")
      : false

    signUp({ context, method, email, sendToProductionAnalytics, userType })
  }

  const forgotPasswordEvent = (analyticsContext?: AnalyticsContext) => {
    const context = analyticsContext || getContext(LoginModalEvents.ForgotPassword)
    forgotPassword(context)
  }

  // Non-modal events
  // (pass analytics location as a param instead of using global tracking object)
  const saveProductToggleEvent = (
    eventName: 'save_product' | 'unsave_product',
    product: Product,
    analyticsLocation?: AnalyticsLocation | AnalyticsContext | '',
  ) => {
    // TODO: Refactor type assertion in task: D1-1507
    const context = (analyticsLocation ? `${analyticsLocation}_${eventName}` : eventName) as AnalyticsContext
    const productUrl = `${getBasePathWithLocale(countryAndLocaleString as CountryAndLocale)}${urlT(getPathName(product, t))}`

    saveProductToggle({ eventName, product, productUrl, context })
  }

  return {
    viewLoginModalEvent,
    viewRegisterModalEvent,
    loginEvent,
    logoutEvent,
    signUpEvent,
    forgotPasswordEvent,
    saveProductEvent: (product: Product, analyticsLocation?: AnalyticsLocation | AnalyticsContext | '') => saveProductToggleEvent('save_product', product, analyticsLocation),
    unsaveProductEvent: (product: Product, analyticsLocation?: AnalyticsLocation | AnalyticsContext | '') => saveProductToggleEvent('unsave_product', product, analyticsLocation),
    submitPasswordResetEvent: (email?: string) => submitPasswordReset(email),
    beginBuyerOnboardingEvent,
    continueBuyerOnboardingEvent,
    addPersonalInfoBuyerOnboardingEvent,
    addPreferencesBuyerOnboardingEvent,
    addCompanyInfoBuyerOnboardingEvent,
    submitBuyerOnboardingEvent,
    updateAccountDetailsEvent,
    updateDeliveryDetailsEvent,
    updateBillingDetailsEvent,
    setDatalayerUserId,
  }
}

export default useUserAnalyticsEvents
