import { Button } from 'rsuite'
import { ReactNode } from 'react'

import styles from '../../styles/SelectTile.module.less'

interface SelectTileProps {
  onClick: () => void
  active: boolean
  children: ReactNode
  direction?: 'row' | 'column'
  className?: string
  justifyCenter?: boolean
  alignCenter?: boolean
}

const SelectTile = (props: SelectTileProps) => {
  const {
    active,
    onClick,
    children,
    direction = 'column',
    justifyCenter,
    alignCenter,
    className = '',
  } = props

  return (
    <Button
      appearance="ghost"
      onClick={onClick}
      className={`
        ${styles['select-tile']}
        ${active ? styles.active : ''}
        ${direction === 'row' ? styles['direction-row'] : ''}
        ${justifyCenter ? styles['justify-center'] : ''}
        ${alignCenter ? styles['align-center'] : ''}
        ${className}`}
    >
      {children}
    </Button>
  )
}

export default SelectTile
