import { useEffect, useRef } from 'react'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'

import models, { AppModels } from '../cypress/utils/models'

type ModelName = keyof AppModels

type ModelProps<T extends ModelName> = {
  [K in keyof ConstructorParameters<typeof models[T]>[0]]:
  ConstructorParameters<typeof models[T]>[0][K]
}

const getExistingModel = <T extends ModelName>(modelName: T) => (
  (typeof window !== 'undefined' ? window : {}) as unknown as AppModels
)[modelName]

const initializeModel = <T extends ModelName>(
  modelName: T,
  modelProps: Partial<ModelProps<T>>,
  shouldDeepMergeProps = true,
) => {
  const existingModel = getExistingModel(modelName)
  const mergedProps = shouldDeepMergeProps
    ? merge(existingModel, modelProps)
    : { ...existingModel, ...modelProps }

  const model = new models[modelName](mergedProps as any)

  if (window.Cypress) {
    (window as unknown as AppModels)[modelName] = model as any
  }
}

const useCyModel = <T extends ModelName>(
  modelName: T,
  modelProps: ModelProps<T>,
  shouldDeepMergeProps = true,
) => {
  const modelPropsRef = useRef(modelProps)

  if (!isEqual(modelPropsRef.current, modelProps)) {
    modelPropsRef.current = modelProps
  }

  useEffect(() => {
    initializeModel(modelName, modelProps, shouldDeepMergeProps)
  }, [modelName, modelPropsRef.current])
}

export default useCyModel

export const useCyModelWithProps = <T extends ModelName>(
  modelName: T,
  modelProps: Partial<ModelProps<T>>,
  shouldDeepMergeProps = true,
) => {
  const modelPropsRef = useRef(modelProps)

  if (!isEqual(modelPropsRef.current, modelProps)) {
    modelPropsRef.current = modelProps
  }

  useEffect(() => {
    const existingModel = getExistingModel(modelName)

    if (!existingModel) {
      return
    }

    initializeModel(modelName, modelProps, shouldDeepMergeProps)
  }, [modelName, modelPropsRef.current])
}
