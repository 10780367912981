import { BillingAddressFormValues } from '../../../components/BuyerProfile/BillingAddressForm/utils'
import { DeliveryAddressFormValues } from '../../../components/BuyerProfile/DeliveryAddressForm/utils'
import { CartResponse, SingleOrderResponse, WithSuppliers } from '../../../utils/types/Order'

type CheckoutModel = Partial<{
  order: SingleOrderResponse | null
  cartOrderData: WithSuppliers<CartResponse> | null
  address: Partial<{
    deliveryAddressFormValues: DeliveryAddressFormValues
    billingAddressFormValues: BillingAddressFormValues
    setBillingFormValues: (formValues: BillingAddressFormValues) => void
    isMatchingBillingAndDelivery: boolean
    isEditingDeliveryAddress: boolean
    isEditingBillingAddress: boolean
    isLoading: boolean
    showGuestCheckoutEmailField: boolean
    guestEmailAddress?: string
    isContinueButtonDisabled: boolean
    handleRecipientNameChange: (formValue: Record<string, any>) => void
  }>
  payment: {}
  confirm: {}
  summary: {}
}>

class Checkout implements CheckoutModel {
  order: CheckoutModel['order']

  cartOrderData: CheckoutModel['cartOrderData']

  address: CheckoutModel['address']

  payment: CheckoutModel['payment']

  confirm: CheckoutModel['confirm']

  summary: CheckoutModel['summary']

  constructor(model: CheckoutModel) {
    this.order = model.order
    this.cartOrderData = model.cartOrderData
    this.address = model.address
    this.payment = model.payment
    this.confirm = model.confirm
    this.summary = model.summary
  }
}

export default Checkout
