import { FormInstance } from 'rsuite'
import { Dispatch } from 'react'
import { ItemDataType } from 'rsuite/esm/@types/common'

import { EditAction } from '../../../services/editProductReducer'
import { Country, CountryId } from '../../../utils/constants'
import { ApplyFormData, SupplierShippingData, UseSupplierProfileDataReturn } from '../../../utils/types/Profile'
import { PartialProduct, Product, ProductAttribute, ProductPrice, ProductStock, ProductText, VariantTypeValue } from '../../../utils/types/Product'
import { ProductProperty } from '../../../utils/types/Resource'
import { FeatureValueType } from '../../../utils/types/featureType'

type ShippingDetailsState = {
  shippingData: SupplierShippingData
  setShippingData: (key: 'country' | 'limit' | 'days' | 'mov' | 'fee', value: string) => void
}

type SalesUnitProperties = {
  'per-box': ProductProperty | undefined
  'per-carton': ProductProperty | undefined
}

export type PriceProperties = Exclude<keyof ProductPrice, 'price.taxrates' | 'price.status' | 'price.taxflag'>

interface SupplierModel {
  fiShippingDetails?: ShippingDetailsState
  svShippingDetails?: ShippingDetailsState
  deShippingDetails?: ShippingDetailsState

  applyFormErrors?: Record<string, string | undefined>
  applyFormValues?: ApplyFormData
  handleApplyFormChange?: (values: ApplyFormData) => void
  registerSupplier?: () => void
  applyFormRef?: React.RefObject<FormInstance>

  supplierProfileData?: UseSupplierProfileDataReturn
  profileDetails?: {
    formValues: Record<'company' | 'firstname' | 'lastname', string>
    setFormValues: (values: Record<'company' | 'firstname' | 'lastname', string>) => void
    updateProfileDetails: () => Promise<void>
  }
  supplierText?: {
    onChange: (value: string) => void
  }

  manageProducts?: {
    products: Product[]
  }

  manageEditProduct?: {
    product?: PartialProduct
    currentProduct: PartialProduct
    dispatch: Dispatch<EditAction>
  }

  manageProductText?: Partial<{
    [key in ProductText['text.type']]: {
      onChange: (value: string) => void
    }
  }>

  manageSalesUnit?: {
    properties: SalesUnitProperties
    updateProperty: (propertyName: keyof SalesUnitProperties, value: string) => void
  }

  managePriceProperty?: Partial<{
    [key in PriceProperties]: {
      price?: ProductPrice
      priceType: 'default' | 'cost'
      onChange: (value: string | number) => void
    }
  }>

  manageStockLevel?: Partial<{
    [key: string]: {
      value: ProductStock[] | ProductProperty[]
      updateStock: (level: string | number) => void
    }
  }>

  manageSku?: Partial<{
    [key: string]: {
      sku?: string
      onChange: (value: string) => void
    }
  }>

  manageOriginalSku?: Partial<{
    [key: string]: {
      originalSku?: ProductProperty
      onChange: (value: string) => void
    }
  }>

  manageVariantType?: {
    variantType: VariantTypeValue
    onChange: (value: VariantTypeValue) => void
  }

  manageVariantName?: Partial<{
    [key: string]: {
      variantName: string
      variantAttribute?: ProductAttribute
      onChange: (value: string) => void
      options: ItemDataType<string>[]
    }
  }>

  manageFeatureValue?: Partial<{
    [key: string]: {
      value: ProductAttribute[]
      featureValueType: FeatureValueType
      valueType: ProductAttribute[]
      handleChange: ((value: string[]) => void) | ((value: string | number) => void)
    }
  }>

  manageStandards?: Partial<{
    [standardsCode: string]: {
      value: string[]
      options: {
        value: string
        label: string
      }[]
      handleChange: (value: string | null) => void
    }
  }>

  manageStandardsSingle?: Partial<{
    [standardsTypeCode: string]: Partial<{
      [level in 'version' | 'test']: {
        value?: string[] | string
        options: {
          value: string
          label: string
        }[]
        handleChange: (value: string[] | string) => void
      }
    }>
  }>

  manageProductStatus?: {
    status: number
    changeStatus(status: boolean): void
  }

  manageActions?: {
    confirmDispatch: Dispatch<EditAction>
  }
}

class Supplier implements SupplierModel {
  fiShippingDetails: SupplierModel['fiShippingDetails']

  svShippingDetails: SupplierModel['svShippingDetails']

  deShippingDetails: SupplierModel['deShippingDetails']

  applyFormErrors: SupplierModel['applyFormErrors']

  applyFormValues: SupplierModel['applyFormValues']

  handleApplyFormChange: SupplierModel['handleApplyFormChange']

  registerSupplier: SupplierModel['registerSupplier']

  applyFormRef: SupplierModel['applyFormRef']

  supplierProfileData: SupplierModel['supplierProfileData']

  profileDetails: SupplierModel['profileDetails']

  supplierText: SupplierModel['supplierText']

  manageProducts: SupplierModel['manageProducts']

  manageEditProduct: SupplierModel['manageEditProduct']

  manageProductText: SupplierModel['manageProductText']

  manageSalesUnit: SupplierModel['manageSalesUnit']

  managePriceProperty: SupplierModel['managePriceProperty']

  manageStockLevel: SupplierModel['manageStockLevel']

  manageSku: SupplierModel['manageSku']

  manageOriginalSku: SupplierModel['manageOriginalSku']

  manageVariantType: SupplierModel['manageVariantType']

  manageVariantName: SupplierModel['manageVariantName']

  manageFeatureValue: SupplierModel['manageFeatureValue']

  manageStandards: SupplierModel['manageStandards']

  manageStandardsSingle: SupplierModel['manageStandardsSingle']

  manageProductStatus: SupplierModel['manageProductStatus']

  manageActions: SupplierModel['manageActions']

  constructor(model: SupplierModel) {
    this.fiShippingDetails = model.fiShippingDetails
    this.svShippingDetails = model.svShippingDetails
    this.deShippingDetails = model.deShippingDetails

    this.applyFormErrors = model.applyFormErrors
    this.applyFormValues = model.applyFormValues
    this.handleApplyFormChange = model.handleApplyFormChange
    this.registerSupplier = model.registerSupplier
    this.applyFormRef = model.applyFormRef

    this.supplierProfileData = model.supplierProfileData
    this.profileDetails = model.profileDetails
    this.supplierText = model.supplierText

    this.manageProducts = model.manageProducts
    this.manageEditProduct = model.manageEditProduct
    this.manageProductText = model.manageProductText
    this.manageSalesUnit = model.manageSalesUnit
    this.managePriceProperty = model.managePriceProperty
    this.manageStockLevel = model.manageStockLevel
    this.manageSku = model.manageSku
    this.manageOriginalSku = model.manageOriginalSku
    this.manageVariantType = model.manageVariantType
    this.manageVariantName = model.manageVariantName
    this.manageFeatureValue = model.manageFeatureValue
    this.manageStandards = model.manageStandards
    this.manageStandardsSingle = model.manageStandardsSingle
    this.manageProductStatus = model.manageProductStatus
    this.manageActions = model.manageActions
  }

  getCountry(countryId: CountryId) {
    return Country[countryId]
  }
}

export default Supplier
