import { Translate } from 'next-translate'
import { ReactNode, RefObject } from 'react'
import { Browser } from 'detect-browser'
import { createHash } from 'crypto'
import { sanitize } from 'isomorphic-dompurify'
import parse from 'html-react-parser'

import { OldestSupportedBrowsers, QueryParam } from './constants'
import { isDefined } from './types/misc'
import { Product } from './types/Product'

/* This function will convert days into approximate weeks */
export const deliveryHelper = (
  days: number,
  t: Translate | ((s: string) => string) = (s: string) => s,
  short: boolean = false,
): string => {
  const shortSuffix = short ? '_short' : ''
  if (!days) return ''

  if (days < 4) return t(`{{ count }}-day delivery${shortSuffix}`, { count: days })
  if (days > 3 && days < 8) return t(`{{ count }}-week delivery${shortSuffix}`, { count: 1 })
  if (days > 7 && days < 15) return t(`{{ count }}-weeks delivery${shortSuffix}`, { count: 2 })
  if (days > 14 && days < 22) return t(`{{ count }}-weeks delivery${shortSuffix}`, { count: 3 })
  if (days > 21 && days < 29) return t(`{{ count }}-weeks delivery${shortSuffix}`, { count: 4 })
  if (days > 28) return t(`4-week+ delivery${shortSuffix}`)
  return ''
}

export const formatToRows = (string: string, maxCharsPerRow: number) => {
  // No splitting needed if text naturally splits to two rows
  if (string.length > maxCharsPerRow) return string
  const includesSpaces = !!(string.match(/ /g) || []).length
  // Some strings lack spaces to split by, fallback to splitting by dash instead
  const words = includesSpaces ? string.split(' ') : string.split('-')
  const rowSplitIndex: number = Math.ceil(words.length / 2)
  words.splice(rowSplitIndex, 0, '\n')
  const dash = !includesSpaces && string.includes('-') ? '-' : '' // If string only contained '-' and no spaces
  return words.join(' ').replace(' \n ', `${dash}\n`) // Clean up whitespace and add - if needed
}

export const getEllipsesText = (text: string, length: number) => {
  if (text.length < length) return text
  return `${text.slice(0, length)}...`
}

export const applyIf = <F extends (...args: any) => any>(
  f: F,
  args: Parameters<F>,
): ReturnType<F> | undefined => {
  if (args.every(isDefined)) {
    return f(...args)
  }
  return undefined
}

export const not = <F extends (...args: any) => boolean>(f: F) => (
  (...args: Parameters<F>) => !f(...args)
)

export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

export const capitalizeWords = (inputString: string) => {
  const words = inputString.split(' ')
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  return capitalizedWords.join(' ')
}

// replacing characters for sitemap generation
export const escapeHTML = (str: string) => str.replace(
  /[&<>'"]/g,
  (tag) => ({
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    "'": '&#39;',
    '"': '&quot;',
  }[tag] || tag),
)

export const clearSearch = (asPath: string): string => {
  if (!asPath.includes('?')) return ''
  const [path, queries] = asPath.split('?')
  const queriesWithoutSearch = queries
    .split('&')
    .filter((queryParam) => !queryParam.includes(`${QueryParam.search}=`))
    .join('&')

  const querySymbol = queriesWithoutSearch ? '?' : ''
  return path + querySymbol + queriesWithoutSearch
}

export const getPercentage = (num: number, percentage: number) => (num / 100) * percentage

export const removeDuplicates = <T>(array: (T | undefined)[]): T[] => (
  array.filter((a, b) => array.indexOf(a) === b).filter(isDefined))

const specialCharactersDict: Readonly<Record<string, string>> = {
  µ: 'u', // This one is the small greek letter mu
  μ: 'u', // This one is the micro sign, looks the same as mu but has a different unicode
  ø: 'o',
  ß: 'ss',
}
const specialNonLatinCharsRegexp = new RegExp(`[${Object.keys(specialCharactersDict).join('')}]`, 'gi')

export const normalizeString = (targetString: string) => targetString
  .trim()
  .replace(/[&/\\#,+()$~%.'":*?<>–{}©®°—\s]+/g, '-') // to replace any special characters and spaces with '-'
  .replace(/-+$/, '') // remove any '-' at the end of the string
  .normalize('NFD')
  .replace(/\p{Diacritic}/gu, '') // replace any special dialect char (such as ä, å, ü, ö) with the non-dialect normal char
  .replace(specialNonLatinCharsRegexp, (char) => (specialCharactersDict[char] || char))
  // White list chars, allowed only the below ones
  .replace(/[^a-zA-Z0-9]+/g, '-')
  // Remove consecutive dashes
  .replace(/-+/g, '-')
  // Remove dash from url end
  .replace(/-$/, '')
  // Remove dash from url start
  .replace(/^(-)/, '')

export const getRefDimension = (
  attribute: 'top' | 'bottom' | 'left' | 'right' | 'height' | 'width' | 'x' | 'y',
  refElement?: RefObject<HTMLDivElement | HTMLElement> | null,
) => refElement?.current?.getBoundingClientRect()[attribute] ?? 0

/**
 * Only use this function in client-side code or unsensitive data hashing,
 * such as internal traffic user id.
 *
 * Encrypt any string using SHA-256 algorithm and a salt
 * @param targetString
 * @param salt
 */
export const encryptStringBrowser = async (string: string, salt: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(`${string}${salt}`)

  const hash = await crypto.subtle.digest('SHA-256', data)

  // Convert hash to hexadecimal string
  const hashHex = Array.from(new Uint8Array(hash)).map((int) => int.toString(16).padStart(2, '0')).join('')

  return hashHex
}

/**
 * Needed because sensitive data hashing should not be handled in the client,
 * and createHash function from 'crypto' library is only available in Node.js runtime.
 * Use this function in API routes and other server-side code.
 *
 * Encrypt any string using SHA-256 algorithm and a salt
 * @param string
 * @param salt
 * @returns encrypted string
 */
export const encryptStringServer = (string: string, salt: string) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if (typeof window !== 'undefined') {
    console.warn('Warning: encryptStringServer is intended for server-side use only.')
  }

  const data = `${string}${salt}`
  const hash = createHash('sha256')

  hash.update(data)

  // Convert hash to hexadecimal string
  const hashHex = hash.digest('hex')

  return hashHex
}

export const getRandomItemFromlist = <T = string>
  (list: T[]) => list[Math.floor(Math.random() * list.length)]

export const hasMultipleOccurrences = (
  arr: (string | number)[],
  item: string | number,
): boolean => {
  const occurrences = arr.filter((element) => element === item)
  return occurrences.length > 1
}

export const hasOneValueZero = (value1: number, value2: number): boolean => (
  (value1 === 0 && value2 !== 0)
  || (value1 !== 0 && value2 === 0)
)

export const checkIsBrowserSupported = (
  name: Browser | 'bot' | 'node' | 'react-native' | undefined,
  version: string | null | undefined,
) => {
  const majorVersion = Number(version?.split('.')[0])
  const browsers = Object.keys(OldestSupportedBrowsers)
  if (name === 'opera-mini') {
    return false
  }

  for (let index = 0; index < browsers.length; index += 1) {
    const browser = browsers[index]
    if (name === browser && majorVersion < OldestSupportedBrowsers[browser]) {
      return false
    }
  }

  return true
}

export const getShortAvatarName = (name?: string) => {
  if (!name) return ''

  const nameArr = name.split(' ')
  const firstName = nameArr.at(0) || ''
  const lastName = nameArr.at(-1)

  if (!lastName) {
    return `${firstName.at(0)}${firstName.at(-1)}`
  }

  return `${firstName.at(0)}${lastName.at(0)}`
}

/**
 * Recursively sanitize all object strings and parses them into react nodes
 * Mainly used to sanitize injected html from feature flag json
 * Only to be used client side
 */

type SanitizedObject<T> = {
  [K in keyof T]: T[K] extends string ? string | ReactNode : T[K]
}

export const parseAndSanitizeObject = <T extends object>(object: T): SanitizedObject<T> => {
  const isObject = (value: any): value is Record<string, any> => typeof value === 'object' && value !== null && !Array.isArray(value)

  const recursiveSanitize = (obj: any): any => {
    if (typeof obj === 'string') {
      // Sanitize and parse strings into react components
      return parse(sanitize(obj)) as string | ReactNode
    } if (Array.isArray(obj)) {
      // Recursive case for arrays
      return obj.map(recursiveSanitize)
    } if (isObject(obj)) {
      // Recursive case for objects
      return Object.keys(obj).reduce((acc, key) => {
        acc[key] = recursiveSanitize(obj[key])
        return acc
      }, {} as Record<string, any>)
    }
    return obj
  }

  return recursiveSanitize(object) as SanitizedObject<T>
}

/**
 * Replaces {{ placeholders }} in a string with values from an object
 */
type ReplacementValues = { [key: string]: string }

export const replaceStringPlaceholders = (
  template: string, values: ReplacementValues,
): string => Object.keys(values).reduce((str, key) => {
  const regex = new RegExp(`{{\\s*${key}\\s*}}`, 'g')

  return str.replace(regex, values[key])
}, template)

export const compareStringsForSort = (a: string, b: string) => a.localeCompare(b)

export const checkMainSkuClashing = (
  variants: Product[],
  mainSkuCode: string,
) => variants.length > 1
  && variants.some((variant) => mainSkuCode
    && variant['product.code'] === mainSkuCode)

export const camelCaseToSentence = (str: string) => {
  // Add a space before each uppercase letter and lowercase the rest
  let result = str.replace(/([A-Z])/g, ' $1').toLowerCase()
  // Capitalize the first letter of the result
  result = result.charAt(0).toUpperCase() + result.slice(1)
  return result
}

export const getPaymentTerms = (
  invoiceType: string, paymentMethod: string | null,
): string => {
  if (invoiceType === 'credit-card' && paymentMethod) {
    return paymentMethod
  }

  return 'Net 14'
}
