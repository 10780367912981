import TagManager from 'react-gtm-module'

import { AnalyticsContext } from '../types/analytics'

const beginChangeAddress = (context: AnalyticsContext) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'begin_change_address',
      context,
    },
  })
}

export default beginChangeAddress
