import { CartSupplierProducts } from '../Cart'
import {
  CartData,
  CartOrderData,
  CartOrderResponse, CartProduct, CartRelationships,
  CartResponse, ErrorResponse,
  GenericOrderData,
  OrderAddressInclude,
  OrderCouponInclude,
  OrderCouponProductInclude,
  OrderInclude,
  OrderProductInclude, OrderRelationships,
  OrderServiceAttributeInclude,
  OrderServiceInclude,
  OrderType,
  RelationshipObject,
  SingleOrderResponse,
} from '../Order'
import { Product } from '../Product'

export const responseHasErrors = (
  response: CartOrderResponse | SingleOrderResponse | ErrorResponse,
): response is ErrorResponse => (response as ErrorResponse).errors !== undefined
export const isCartResource = (
  resource: SingleOrderResponse,
): resource is CartResponse => resource.data?.type === 'basket'
export const isOrderResource = (
  resource: SingleOrderResponse,
): resource is SingleOrderResponse<'order'> => resource.data?.type === 'order'
export const isCartData = (
  resource: CartOrderData,
): resource is CartData => resource?.type === 'basket'

export const isSupplierMappedResource = (
  resource: CartOrderResponse | SingleOrderResponse | CartSupplierProducts,
): resource is CartSupplierProducts => (
  (resource as CartSupplierProducts).supplierMappedProducts !== undefined
)

export const isCartRelationships = (
  relationships: CartRelationships | OrderRelationships,
): relationships is CartRelationships => {
  const cartRelationships = relationships as CartRelationships
  // Check all types of includes since they are not guaranteed to always be present
  const productRelationships = cartRelationships['basket.product']?.data.length ?? 0
  const addressRelationships = cartRelationships['basket.service']?.data.length ?? 0
  const serviceRelationships = cartRelationships['basket.service']?.data.length ?? 0
  return productRelationships + addressRelationships + serviceRelationships > 0
}

export const getIncluded = (response: CartOrderResponse | SingleOrderResponse): OrderInclude[] => (
  // Cast needed since the type is not automatically narrowed from
  // OrderInclude<'basket'> | OrderInclude<'order'> => OrderInclude
  response.included || [] as OrderInclude[]
)
export const isProductInclude = <T extends OrderType = OrderType>(
  include: OrderInclude<T>,
): include is OrderProductInclude<T> => (
    (include.type === 'basket.product' || include.type === 'order/product')
    && Number((include as OrderProductInclude<T>).attributes['order.product.price']) > 0
  )
export const isServiceInclude = <T extends OrderType = OrderType>(
  include: OrderInclude<T>,
): include is OrderServiceInclude<T> => (
    include.type === 'basket.service' || include.type === 'order/service'
  )
export const isOrderServiceAttributeInclude = <T extends OrderType = OrderType>(
  include: OrderInclude<T>,
): include is OrderServiceAttributeInclude<T> => (
    include.type === 'order/service/attribute'
  )
export const isAddressInclude = <T extends OrderType = OrderType>(
  include: OrderInclude<T>,
): include is OrderAddressInclude<T> => (
    include.type === 'basket.address' || include.type === 'order/address'
  )

export const getProductIncluded = (
  response: CartOrderResponse | SingleOrderResponse,
): OrderProductInclude[] => (
  getIncluded(response).filter(isProductInclude)
)
export const getServiceIncluded = (
  response: CartOrderResponse | SingleOrderResponse,
): OrderServiceInclude[] => (
  getIncluded(response).filter(isServiceInclude)
)
export const getAddressIncluded = (
  response: CartOrderResponse | SingleOrderResponse,
): OrderAddressInclude[] => (
  getIncluded(response).filter(isAddressInclude)
)

export const getOrderProductRelationships = (
  orderData: GenericOrderData<'order'>,
): RelationshipObject<'order/product'>['data'] => (
  orderData.relationships['order/product']?.data ?? []
)

export const isCouponInclude = <T extends OrderType = OrderType>(
  include: OrderInclude<T>,
): include is OrderCouponInclude<T> => (
    include.type === 'basket.coupon' || include.type === 'order/coupon'
  )

export const isCouponProductInclude = <T extends OrderType = OrderType>(
  include: OrderInclude<T>,
): include is OrderCouponProductInclude<T> => (
    (include.type === 'basket.product' || include.type === 'order/product')
    && Number((include as OrderProductInclude<T>).attributes['order.product.price']) < 0
  )

export const isCartProduct = (
  product: Product | CartProduct,
): product is CartProduct => (product as CartProduct)['order.product.id'] !== undefined
