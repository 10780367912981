/* eslint-disable import/no-cycle */ // TODO: Fix Resource - AimeosApi - Order import cycle
import { ProductGql, ResourceGql } from './Product'
import { ResourceName } from './Resource'

/**
 * Copied from https://github.com/boromisp/jsonapi-tools/tree/master/packages/jsonapi-types
 */
type IJSONValue = null | string | number | boolean | Date | IJSONObject | IJSONArray

interface IJSONObject {
  [x: string]: IJSONValue
}

// tslint:disable-next-line:no-empty-interface
interface IJSONArray extends Array<IJSONValue> {}

/**
 * http://jsonapi.org/format/1.0/#document-links
 */
type ILink = string | {
  href: string
  meta?: IJSONObject
}

/**
 * http://jsonapi.org/format/1.0/#fetching-pagination
 */
interface IPaginationLinks {
  first?: ILink | null
  last?: ILink | null
  prev?: ILink | null
  next?: ILink | null
}

/**
 * http://jsonapi.org/format/1.0/#document-links
 * http://jsonapi.org/format/1.0/#document-top-level
 * http://jsonapi.org/format/1.0/#document-resource-object-relationships
 */
interface IResourceLinks extends IPaginationLinks {
  last?: ILink | null
  next?: ILink | null
  self?: ILink | null
}

interface ICartLinks {
  self: ILink
  'basket/product': ILink
  'basket/address': ILink
  'basket.coupon': ILink
  'basket/service': ILink
}

interface IRelationshipLinks extends IResourceLinks {
  related?: ILink | null
}

type ILinks = IResourceLinks | IRelationshipLinks

/**
 * http://jsonapi.org/format/1.0/#document-jsonapi-object
 */
interface IJSONAPIObject {
  version?: string
  meta?: IJSONObject
}

interface IAimeosAttribute {
  code: string
  type: string
  label: string
  public: boolean
  default: string | null
  required: boolean
}

interface IAimeosAttributes {
  [key: string]: IAimeosAttribute
}

interface IAimeosMeta {
  prefix: string | null
  resources: { [key: string]: string }
  attributes: IAimeosAttributes
  csrf?: { name: string, value: string }
  locale?: any
  total: number
}

interface IBatchActionMeta {
  'batch-key'?: string
  op: 'action'
}

interface IBatchCreateMeta {
  'batch-key': string
  op?: 'create'
}

interface IBatchUpdateMeta {
  'batch-key'?: string
  op: 'update'
}

interface IBatchDeleteMeta {
  'batch-key'?: string
  op: 'delete'
}

type TBatchMeta = IBatchCreateMeta | IBatchUpdateMeta | IBatchDeleteMeta | IBatchActionMeta
type TMeta = IJSONObject | TBatchMeta | IAimeosMeta

function isBatchMeta(meta?: TMeta): meta is TBatchMeta {
  return Boolean(meta && (
    (meta as TBatchMeta).op
    || (meta as TBatchMeta)['batch-key']))
}

/**
 * http://jsonapi.org/format/1.0/#document-resource-identifier-objects
 */
interface IResourceIdentifierObject {
  type: string
  id: string
  meta?: TMeta
  links?: ICartLinks
  attributes?: IJSONObject
}

interface IAggregateIdentifierObject {
  type: string
  id: number
  attributes: number
}

/**
 * http://jsonapi.org/format/1.0/#document-resource-object-linkage
 */
type IResourceLinkage = IResourceIdentifierObject | IResourceIdentifierObject[] | null

/**
 * http://jsonapi.org/format/1.0/#document-resource-object-relationships
 */
interface IRelationshipObject {
  data: IResourceLinkage
  links?: IRelationshipLinks
  meta?: IJSONObject
}

interface IAggregateObject {
  data: IAggregateIdentifierObject[]
  links?: IRelationshipLinks
  meta?: IJSONObject
}

/**
 * http://jsonapi.org/format/1.0/#document-resource-objects
 */
interface IResourceObjectBase {
  id?: string
  type: string
  attributes?: IJSONObject
  relationships?: {
    [field: string]: IRelationshipObject
  }
  links?: IResourceLinks
  meta?: TMeta
}

interface IResourceObject {
  id: string
  type: string
  attributes?: IJSONObject
  relationships?: {
    [field: string]: {
      data: IResourceLinkage
      links?: IRelationshipLinks
      meta?: IJSONObject
    }
  }
  links?: IResourceLinks
  meta?: TMeta
}

interface IBatchOperation extends IResourceObjectBase {
  meta: IBatchCreateMeta | IBatchUpdateMeta | IBatchDeleteMeta
}

interface IBatchAction extends IResourceObjectBase {
  meta: IBatchActionMeta
}

function isBatchAction(val: IBatchOperation | IBatchAction): val is IBatchAction {
  return 'action' in val
}

/**
 * http://jsonapi.org/format/1.0/#error-objects
 */
interface IErrorObject {
  id?: string
  links?: { about: ILink }
  status?: number
  code?: string | number
  title?: string
  detail?: string
  source?: {
    pointer?: string
    parameter?: string
  }
  meta?: IJSONObject
}

interface IDocumentBase {
  jsonapi?: IJSONAPIObject
  meta?: IJSONObject | IAimeosMeta
}

interface IOptionsDocument extends IDocumentBase {
  meta: IAimeosMeta
}

/**
 * http://jsonapi.org/format/1.0/#fetching-resources-responses
 * http://jsonapi.org/format/1.0/#fetching-includes
 * http://jsonapi.org/format/1.0/#document-top-level
 */
interface ISuccessResourceDocument extends IDocumentBase {
  data: IResourceObject | IResourceObject[] | null
  included?: IResourceObject[]
  links?: IResourceLinks
}

/**
 * http://jsonapi.org/format/1.0/#fetching-resources-responses-404
 */
interface IErrorResourceDocument extends IDocumentBase {
  errors: IErrorObject[]
  links?: IResourceLinks
}

/**
 * http://jsonapi.org/format/1.0/#fetching-relationships-responses
 * http://jsonapi.org/format/1.0/#document-top-level
 */
interface ISuccessRelationshipDocument extends IDocumentBase {
  data: IResourceLinkage
  links?: IRelationshipLinks
}

/**
 * http://jsonapi.org/format/1.0/#fetching-relationships-responses-404
 */
interface IErrorRelationshipDocument extends IDocumentBase {
  errors: IErrorObject[]
  links?: IRelationshipLinks
}

/**
 * http://jsonapi.org/format/1.0/#crud-updating
 */
interface IUpdateResourceDocument extends IDocumentBase {
  data: IResourceObjectBase
}

interface IBatchResourceDocument extends IDocumentBase {
  batch: Array<IBatchOperation | IBatchAction>
}

/**
 * http://jsonapi.org/format/1.0/#crud-creating-responses
 */
interface ICreateResponseDocument extends IDocumentBase {
  data: IResourceObject | IResourceObject[]
}

interface IBatchResponseDocument extends IDocumentBase {
  data: Array<IResourceObject | null>
}

interface SearchResourceSuccess<T extends ResourceGql> {
  searchResource: T extends ProductGql ? T[] : GqlItems<T>
}

export type GqlItems<Gql extends Exclude<ResourceGql, ProductGql>> = {
  items: Gql[]
}

type SaveResourceSuccess = `save${Capitalize<ResourceName>}`

type SaveProductSuccess = {
  [p in SaveResourceSuccess]: {
    id: string
    lists?: {
      product: { refid: string }[]
    }
  }
}

interface SaveProductResponse{
  data: SaveProductSuccess | undefined
  errors: any
}

interface SearchResourceResponse<T extends ResourceGql> {
  data: SearchResourceSuccess<T> | undefined
  errors?: any
}

// Interfaces and types used in own api endpoints such as ${apiUrl}/api/email/resend

type AimeosResponseStatus = 'Success' | 'Error' | 'Loading' | 'Unset'

interface IAimeosResponse<T = undefined> {
  status: AimeosResponseStatus
  message: string
  data?: T
}

interface ValidationError {
  message: string
  errors: Record<string, string>
}

const isValidationError = <T>(resp: IAimeosResponse<T> | ValidationError):
resp is ValidationError => (
    (resp as ValidationError).errors !== undefined
  )

type ISuccessDocument =
  ISuccessResourceDocument
  | ISuccessRelationshipDocument
  | ICreateResponseDocument
  | IBatchResponseDocument

type IErrorDocument =
  IErrorResourceDocument
  | IErrorRelationshipDocument

type IResponseDocument =
  ISuccessResourceDocument
  | IErrorResourceDocument
  | ISuccessRelationshipDocument
  | IErrorRelationshipDocument
  | ICreateResponseDocument
  | IBatchResponseDocument
  | IOptionsDocument

type IGetResponseDocument =
  ISuccessResourceDocument
  | IErrorResourceDocument
  | ISuccessRelationshipDocument
  | IErrorRelationshipDocument

function isSuccess(document: IResponseDocument): document is ISuccessDocument {
  return (document as ISuccessDocument)?.data !== undefined
}

function isGetSuccess(document: IGetResponseDocument): document is ISuccessResourceDocument {
  return (document as ISuccessResourceDocument).data !== undefined
}

function hasLinks(document: IResponseDocument): document is IGetResponseDocument {
  return (document as IGetResponseDocument).links !== undefined
}

function hasIncluded(document: IResponseDocument): document is ISuccessResourceDocument {
  return (document as ISuccessResourceDocument)?.included !== undefined
}

function hasRelated(links: ILinks): links is IRelationshipLinks {
  return (links as IRelationshipLinks).related !== undefined
}

function hasRelationships(data: IResourceObjectBase | IRelationshipObject):
  data is IResourceObjectBase {
  return (data as IResourceObjectBase).relationships !== undefined
}

export type {
  IJSONValue,
  IJSONObject,
  IJSONArray,
  ILink,
  IPaginationLinks,
  IResourceLinks,
  IRelationshipLinks,
  ICartLinks,
  ILinks,
  IJSONAPIObject,
  IBatchActionMeta,
  IBatchCreateMeta,
  IBatchUpdateMeta,
  IBatchDeleteMeta,
  TBatchMeta,
  TMeta,
  IResourceIdentifierObject,
  IAggregateIdentifierObject,
  IResourceLinkage,
  IRelationshipObject,
  IAggregateObject,
  IResourceObjectBase,
  IResourceObject,
  IBatchOperation,
  IBatchAction,
  IErrorObject,
  IDocumentBase,
  ISuccessResourceDocument,
  IErrorResourceDocument,
  ISuccessRelationshipDocument,
  IErrorRelationshipDocument,
  IUpdateResourceDocument,
  IBatchResourceDocument,
  ICreateResponseDocument,
  IBatchResponseDocument,
  ISuccessDocument,
  IErrorDocument,
  IResponseDocument,
  IGetResponseDocument,
  IAimeosMeta,
  IAimeosAttribute,
  IAimeosAttributes,
  IOptionsDocument,
  IAimeosResponse,
  ValidationError,
  SaveProductResponse,
  SaveProductSuccess,
  SearchResourceResponse,
  SearchResourceSuccess,
}

export {
  isBatchMeta,
  isBatchAction,
  isSuccess,
  isGetSuccess,
  hasLinks,
  hasIncluded,
  hasRelated,
  hasRelationships,
  isValidationError,
}
