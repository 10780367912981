// useCustomState.ts
import useSWR, { mutate } from 'swr'

type UseCustomGlobalStateResult<T> = [
  T,
  (newData: T) => Promise<void>,
]

const useCustomGlobalState = <T>(
  key: string,
  initialData: T,
): UseCustomGlobalStateResult<T> => {
  const { data } = useSWR<T>(key, () => initialData, {
    fallbackData: initialData,
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    refreshInterval: 0,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
  })

  const updateData = async (newData: T) => {
    await mutate(key, newData, false)
  }

  return [data as T, updateData]
}

export default useCustomGlobalState
