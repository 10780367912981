import fetcher from './fetcher'
import { CurrencyId } from './types/Product'

const exchangeRateApiRequest = async (from: CurrencyId, to: CurrencyId): Promise<number | null> => {
  const apiUrl = process.env.NEXT_PUBLIC_API_URL
  const response = await fetcher(`${apiUrl}/api/v1/conversion-rate/${from}/${to}`)
  const { data } = await response.json()

  // Lazy error handling
  return data?.rate || null
}

export default exchangeRateApiRequest
