import TagManager from 'react-gtm-module'

import { AnalyticsContext } from '../types/analytics'

const addOrderReference = (orderReference: string, context: AnalyticsContext) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_order_reference',
      order_reference: orderReference,
      context,
    },
  })
}

export default addOrderReference
