/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
// Above eslint rules are disabled because of using <a> in <SafeTranslate> component
import { FC, KeyboardEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { AutoComplete } from 'rsuite'
import omit from 'lodash/omit'
import LegacySearchIcon from '@rsuite/icons/legacy/Search'
import { PickerInstance } from 'rsuite/esm/Picker'
import { useFeature, useFeatureIsOn } from '@growthbook/growthbook-react'

import useUrls from '../../../../services/useUrls'
import { getCountryAndLocaleStrings } from '../../../../utils/locales'
import { QueryParam } from '../../../../utils/constants'
import useRFQModal from '../../../Modals/hooks/useRFQModal'
import useGetAnalyticsLocation from '../../../../services/analytics/useGetAnalyticsLocation'
import { AnalyticsSubLocation } from '../../../../utils/types/analytics'
import SearchIconBorder from '../../../Icons/SearchIconBorder'
import SafeTranslate from '../../../common/SafeTranslate'
import { defaultConfig, typesenseInstantSearchAdapter } from '../../../../utils/typesense/config'
import SearchModal from './SearchModal'

import styles from '../../../../styles/Search.module.less'
import buttonStyles from '../../../../styles/CustomButtons.module.less'

interface DropdownMenuData {
  value: string
  label: string
}

interface SearchBarProps {
  externalSearchCallback?: () => void
  className?: string
  graphicRenderCondition?: 'focus' | 'always'
}

const SearchBar: FC<SearchBarProps> = (props) => {
  const {
    // If there is any callback being passed as a prop, for example to clear some state in the
    // search container - in the case of the mobile search logic, fire it when selecting the
    // search catalog.
    externalSearchCallback = () => {},
    className = '',
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const [isDropdownActive, setIsDropdownActive] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const isNewSearch = useFeatureIsOn('use-typesense-search')
  const { value: typesensePreset } = useFeature<string>('typesense-search-preset')

  const { t } = useTranslation('common')
  const { pushT } = useUrls()
  const { asPath, query, isReady, locale: countryAndLocale } = useRouter()
  const { locale } = getCountryAndLocaleStrings(countryAndLocale)
  const searchQuery = query[QueryParam.search]

  // When clearing search, pass all the queries apart from the search and filters
  const queriesWithoutSearchAndFilters = omit(query, [QueryParam.search, QueryParam.filters])

  const containerRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<PickerInstance>(null)
  const searchInputElement = searchInputRef.current?.root?.getElementsByTagName('input')[0]

  const analyticsLocation = useGetAnalyticsLocation(AnalyticsSubLocation.SearchModal)
  const openRFQModal = useRFQModal({ analyticsLocation })

  // Used for the search dropdown menu
  const searchMenuData: DropdownMenuData[] = [{
    value: searchTerm,
    label: searchTerm,
  }]

  useEffect(() => {
    // Change to localized preset
    typesenseInstantSearchAdapter.updateConfiguration({
      ...defaultConfig,
      collectionSpecificSearchParameters: {
        ...defaultConfig.collectionSpecificSearchParameters,
        products: {
          preset: `${typesensePreset ?? 'default_products'}_${locale}`,
          limit: defaultConfig.collectionSpecificSearchParameters?.products.limit,
        },
      },
    })
  }, [locale, typesensePreset])

  useEffect(() => {
    if (isReady && searchQuery && typeof searchQuery === 'string') {
      setSearchTerm(searchQuery)
    } else {
      setSearchTerm('')
    }
  }, [searchQuery, isReady])

  const pushNewPathOnSearchUpdate = (urlPath: string, hasSearchParam = true) => {
    // If search value was passed, apply timeout to persist the value on the search input
    if (hasSearchParam && searchTerm) {
      setTimeout(() => {
        setSearchTerm(searchTerm)
      }, 0)
    }

    pushT({
      pathname: urlPath,
      query: {
        ...(hasSearchParam && searchTerm
          ? { [QueryParam.search]: searchTerm }
          : queriesWithoutSearchAndFilters
        ),
      },
    },
    undefined,
    { shallow: true })
  }

  const triggerSearch = () => {
    pushNewPathOnSearchUpdate('/products')
    externalSearchCallback()
    setIsDropdownActive(false)
    searchInputElement?.blur()
  }

  const handleEnterKeyPress = useCallback((event: KeyboardEvent<Element>) => {
    if (event.key !== 'Enter') return

    triggerSearch()
  }, [locale, searchTerm])

  const handleClearSearchClick = () => {
    setSearchTerm('')

    const [urlWithoutParams] = asPath.split('?')
    pushNewPathOnSearchUpdate(urlWithoutParams, false)

    // Auto focus input when search is cleared
    searchInputElement?.focus()
  }

  const handleChange = (val: string) => setSearchTerm(val)

  const renderSearchMenuItemLabel = (
    label: ReactNode,
  ) => (
    <div data-testid="search-dropdown">
      <button
        type="button"
        onClick={triggerSearch}
        className={styles['search-term']}
        data-testid="search-option"
      >
        <SearchIconBorder />
        <p>
          <SafeTranslate
            i18nKey='common:Search for "{{ query }}" in products'
            components={[<b />]}
            values={{ query: label }}
          />
        </p>
      </button>
      <p className={styles['call-to-action']}>
        <SafeTranslate
          i18nKey="modals:Can't find a suitable product? Save time and request an offer in a second"
          components={[<button
            type="button"
            className={buttonStyles['link-button']}
            onClick={() => {
              openRFQModal()
            }}
          />]}
        />
      </p>
    </div>
  )

  const searchInput = (
    <div className={styles['search-input-container']}>
      <AutoComplete
        ref={searchInputRef}
        open={!!searchTerm.length && isDropdownActive}
        data={searchMenuData}
        onChange={!isNewSearch ? handleChange : undefined}
        onClick={() => setIsOpen(true)}
        onFocus={() => {
          if (isNewSearch) {
            searchInputElement?.blur()
          }
        }}
        onKeyUp={handleEnterKeyPress}
        container={containerRef.current || undefined}
        className="input-round"
        menuClassName={styles['auto-complete-menu']}
        placement="bottomStart"
        data-testid="search-input"
        placeholder={t('Search for product, category or supplier')}
        value={searchTerm}
        renderMenuItem={(label: ReactNode) => (
          renderSearchMenuItemLabel(label)
        )}
      />
      {searchTerm && (
      // Copied markup from rs-picker, inherits styles from global scope
      <button
        className="rs-picker-toggle-clean"
        type="button"
        tabIndex={-1}
        onClick={handleClearSearchClick}
        data-testid="clear-btn"
      >
        ✕
      </button>
      )}
      <LegacySearchIcon
        className={styles['search-icon']}
        role="button"
        data-testid="search-icon"
        onClick={() => searchTerm && triggerSearch()}
      />
    </div>
  )

  return (
    <div
      ref={containerRef}
      className={`${styles['search-container']} ${className}`}
    >
      <SearchModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isDropdownActive={isDropdownActive}
        externalSearchCallback={externalSearchCallback}
        isNewSearch={isNewSearch}
        onClose={() => {
          setIsOpen(false)
          // Defocus header input when closing modal to not allow editing the text in it
          setTimeout(() => searchInputElement?.blur(), 100)
        }}
      />
      {searchInput}
    </div>
  )
}

export default SearchBar
