import TagManager from 'react-gtm-module'

import { roundToTwoDecimals } from '../math'
import { emptyEcomDataLayer, getCartTotalDiscount, getCartTotalValue, getCartEcomEventItems } from './utils'
import { CartResponse, WithSuppliers } from '../types/Order'
import { Product } from '../types/Product'

interface ViewDetailsConfirmationProps {
  cartData: WithSuppliers<CartResponse>
  getLocalizedProductUrl: (product?: Product) => string
  isVatIncluded: boolean
}

const viewDetailsConfirmation = (
  { cartData, getLocalizedProductUrl, isVatIncluded }: ViewDetailsConfirmationProps,
) => {
  const { data } = cartData

  if (!data) {
    return
  }

  emptyEcomDataLayer() // First empty datalayer
  TagManager.dataLayer({ dataLayer: {
    event: 'view_details_confirmation',
    affiliation: 'Droppe Marketplace',
    value: roundToTwoDecimals(data.attributes['order.price']),
    tax: roundToTwoDecimals(data.attributes['order.taxvalue']),
    shipping: roundToTwoDecimals(data.attributes['order.costs']),
    currency: data.attributes['order.currencyid'],
    coupon: 'none',
    totalValue: getCartTotalValue(cartData),
    discount: getCartTotalDiscount(cartData),
    items: getCartEcomEventItems({
      cartData,
      getLocalizedProductUrl,
      isVatIncluded,
    }),
  } })
}

export default viewDetailsConfirmation
