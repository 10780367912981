import { Locale } from '../external'
import { SHIPMENT_DAYS_DEFAULT, SHIPPING_COST_DEFAULT, SHIPPING_LIMIT_DEFAULT } from '../utils/constants'
import { OrderServiceInclude } from '../utils/types/Order'
import { DeliveryService, Product, Supplier } from '../utils/types/Product'
import { libraryFunctions } from './resources/price'

const { getProductMov } = libraryFunctions

const getSupplierDeliveryCost = (
  supplier: Supplier | undefined,
  countryLocale: Locale | null,
  shouldIncludeVat?: boolean,
): number => {
  if (countryLocale) {
    const locale = countryLocale.toUpperCase() as Uppercase<Locale>
    const costPrice = supplier?.['service.price']?.[locale]
    const cost = shouldIncludeVat
      ? Number(costPrice?.costs) + Number(costPrice?.taxvalue)
      : Number(costPrice?.costs)
    return cost ?? SHIPPING_COST_DEFAULT
  }
  return SHIPPING_COST_DEFAULT
}

export const getShippingCost = (
  service: DeliveryService | undefined,
  countryLocale: Locale | null,
  shouldIncludeVat?: boolean,
) => {
  // Gracefully handling case, if service.price is not an array
  if (service?.price && !service?.price?.[0]) {
    return 0
  }
  const costPrice = service?.price?.find((ele) => (
    ele['price.label'] === countryLocale?.toUpperCase() && (!shouldIncludeVat || ele['price.type'] === 'vat-included')))
  const cost = shouldIncludeVat
    ? Number(costPrice?.['price.costs']) + Number(costPrice?.['price.taxvalue'])
    : Number(costPrice?.['price.costs'])
  return cost ?? SHIPPING_COST_DEFAULT
}

export const getSupplierDeliveryDetails = (
  supplier: Supplier | DeliveryService | undefined,
  countryLocale: Locale | null,
  shouldIncludeVat?: boolean,
): {
  deliveryCost: number
  freeShippingLimit: number
  deliveryTime: number
  minimumOrderValue: number
  isAvailable: boolean
} => {
  const deliveryCost = (supplier && 'price' in supplier ? getShippingCost(supplier, countryLocale, shouldIncludeVat) : null)
?? (supplier && 'supplier.id' in supplier ? getSupplierDeliveryCost(supplier, countryLocale, shouldIncludeVat) : null)
?? SHIPPING_COST_DEFAULT
  const freeShippingLimit = supplier?.['service.config']?.[countryLocale?.toUpperCase() ?? 'FI']?.[shouldIncludeVat ? 'limit_vat' : 'limit'] ?? SHIPPING_LIMIT_DEFAULT
  const deliveryTime = supplier?.['service.config']?.[countryLocale?.toUpperCase() ?? 'FI']?.days ?? SHIPMENT_DAYS_DEFAULT
  const minimumOrderValue = supplier?.['service.config']?.[countryLocale?.toUpperCase() ?? 'FI']?.[shouldIncludeVat ? 'mov_vat' : 'mov'] ?? 0

  return ({
    deliveryCost,
    freeShippingLimit,
    minimumOrderValue,
    deliveryTime,
    isAvailable: countryLocale === null || supplier?.['service.config']?.[countryLocale?.toUpperCase()] !== undefined,
  })
}

export const getDeliveryServices = (data: Product[] | Supplier[]) => {
  const isProduct = (p: Product[] | Supplier[]): p is Product[] => (p[0] as Product)?.['product.id'] !== undefined

  if (isProduct(data)) {
    return data
      .flatMap((product) => (
        product.supplier?.flatMap((supp) => (
          supp?.service || []
        )) || []
      ))
  }

  return data.flatMap((supp) => (
    supp?.service || []
  )) || []
}

export const getOrderServiceAttributes = (data: OrderServiceInclude) => {
  const invoiceType = data?.attributes?.attribute
    ?.find((attr) => (
      attr['order.service.attribute.code'] === 'invoiceType'))
    ?.['order.service.attribute.value'] || ''

  const businessId = data?.attributes?.attribute
    ?.find((attr) => (
      attr['order.service.attribute.code'] === 'taxNumber')) // taxNumber key used in backend for business id
    ?.['order.service.attribute.value'] || ''

  const paymentNotes = data?.attributes?.attribute
    ?.find((attr) => (
      attr['order.service.attribute.code'] === 'paymentNotes'))
    ?.['order.service.attribute.value'] || ''

  const sendOrderConfirmation = data?.attributes?.attribute
    ?.find((attr) => (
      attr['order.service.attribute.code'] === 'sendOrderConfirmation'))
    ?.['order.service.attribute.value'] || ''

  return {
    invoiceType,
    businessId,
    paymentNotes,
    sendOrderConfirmation: sendOrderConfirmation === 'true',
  }
}

export const isProductSpecificFreeDelivery = (product: Product | undefined): boolean => (
  product?.['product.free-shipping'] === true
)
export const isFreeDelivery = (
  product: Product,
  countryLocale: Locale | null,
  isVatIncluded: boolean,
  totalPrice?: number,
) => {
  const {
    deliveryCost,
    freeShippingLimit,
  } = getSupplierDeliveryDetails(product.supplier?.[0], countryLocale, isVatIncluded)
  const mov = getProductMov(product)

  return (
    deliveryCost === 0
    || isProductSpecificFreeDelivery(product)
    || !!(freeShippingLimit && mov >= freeShippingLimit)
    || !!(totalPrice && totalPrice >= freeShippingLimit)
    || freeShippingLimit === 0
  )
}
