import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { v4 as uuidv4 } from 'uuid'

import useCustomGlobalState from './useCustomGlobalState'

interface SearchIdState {
  id: string | null
  lastActivityTimestamp: number | null
  staleId: string | null
}

export interface UseSearchIdResult {
  searchId: string | null
  generateNewSearchId: () => void
  clearSearchId: () => void
}

const useSearchId = (): UseSearchIdResult => {
  const [searchState, setSearchState] = useCustomGlobalState<SearchIdState>('search-id-state', {
    id: null,
    lastActivityTimestamp: null,
    staleId: null,
  })

  const router = useRouter()

  const clearSearchId = () => {
    setSearchState({
      id: null,
      lastActivityTimestamp: null,
      staleId: searchState.id || searchState.staleId,
    })
  }

  const generateNewSearchId = () => {
    const newId = uuidv4()
    const newState = {
      id: newId,
      lastActivityTimestamp: Date.now(),
      staleId: newId,
    }
    setSearchState(newState)
    return newId
  }

  // Reset search ID when navigating away from search pages
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // Only clear if navigating away from search pages
      // Use typeof check to avoid type error when url value is -1 for invalid route change
      if (typeof url === 'string' && !url.includes('search=')) {
        clearSearchId()
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  return {
    searchId: searchState.id,
    generateNewSearchId,
    clearSearchId,
  }
}

export default useSearchId
