// frontend/utils/analytics/searchResultClickEvent.ts
import TagManager from 'react-gtm-module'
import { MouseEvent } from 'react'

import { Locale } from '../../external'
import { ActiveCountryId } from '../constants'
import { AnalyticsContext } from '../types/analytics'
import { getClickType } from '../event-utils'

export interface SearchResultClickEventParams {
  searchTerm: string
  locale: Locale
  country: ActiveCountryId
  resultQuantity: number
  productSku?: string
  supplier: string
  context: AnalyticsContext
  searchDomain: 'product' | 'supplier'
  searchId: string | null // Add searchId to event params
  event: MouseEvent<Element>
}

const searchResultClickEvent = (searchResultClickEventParams: SearchResultClickEventParams) => {
  const {
    searchTerm,
    locale,
    country,
    resultQuantity,
    productSku,
    supplier,
    context,
    searchDomain,
    searchId, // Add searchId to destructuring
    event,
  } = searchResultClickEventParams
  const clickType = getClickType(event)

  TagManager.dataLayer({
    dataLayer: {
      event: 'search_result_click',
      language: locale,
      country,
      search_term: searchTerm,
      result_quantity: resultQuantity,
      product_id: productSku,
      supplier,
      click_type: clickType,
      context,
      search_domain: searchDomain,
      search_id: searchId, // Add to GTM data layer
    },
  })
}

export default searchResultClickEvent
