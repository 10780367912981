import { useRouter } from 'next/router'

import { AnalyticsMainLocationValue } from '../../utils/types/analytics'

const useGetAnalyticsPageLocation = (): AnalyticsMainLocationValue | '' => {
  const { asPath, pathname = '' } = useRouter()

  if (pathname === '/404') return '404'
  if (asPath === '/') return 'front_page'
  if (pathname.includes('/suppliers')) return 'supplier_catalog'
  if (pathname.includes('/supplier')) return 'single_supplier'
  if (pathname.includes('/products')) return 'product_catalog'
  if (pathname.includes('/product')) return 'single_product'
  if (pathname.includes('/checkout')) return 'checkout'
  if (pathname.includes('/my-catalogs')) return 'buyer_catalog'
  if (pathname.includes('/my-orders')) return 'buyer_order_history'
  if (pathname.includes('/my-ordered-products')) return 'buyer_previously_ordered_products'
  if (pathname.includes('/my-products')) return 'buyer_saved_products'
  if (pathname.includes('/my-account')) return 'my_account'
  if (pathname.includes('/cart')) return 'cart'

  return ''
}

export default useGetAnalyticsPageLocation
