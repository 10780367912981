import { useRouter } from 'next/router'

import useTakeRate from './useTakeRate'
import { useCart } from '../components/Cart/CartProvider'
import { Product } from '../utils/types/Product'
import { getCountryAndLocaleStrings } from '../utils/locales'
import { getPrices, Prices } from '../lib/getPrices'
import { useAuth } from './useAuth'

interface UsePriceOptions {
  supplierTotalValue?: number
  shouldCalculateTakeRate?: boolean
  excludeAmount?: number
  excludeQuantity?: number
  includeVat?: boolean
}

/**
 * Hook for all the different kinds of prices of a product.
 * The hook (or the similar getter getPrices)  should be the entry
 * to all price data for a product used in components
 * @param product product object
 * @param selectedQuantity quantity for calculating total prices, MOQs, discounts etc.
 * @param options options to configure how consumption based take rate and
 * shipping weighted prices work
 */
const usePrice = (
  product: Product,
  selectedQuantity: number,
  options: UsePriceOptions = {},
): Prices => {
  const {
    supplierTotalValue = 0,
    shouldCalculateTakeRate = true,
    excludeQuantity = 0,
    excludeAmount = 0,
    includeVat,
  } = options
  const calculateTakeRate = useTakeRate()
  const { locale: countryAndLangString } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLangString)
  const { modifiedCart } = useCart()
  const { isVatIncluded } = useAuth()

  const shouldIncludeVat = includeVat ?? isVatIncluded

  return getPrices(product, selectedQuantity, {
    country,
    modifiedCart,
    supplierTotalValue,
    takeRateExcludeAmount: excludeAmount,
    calcTakeRate: shouldCalculateTakeRate ? calculateTakeRate : undefined,
    takeRateExcludeQuantity: excludeQuantity,
    isVatIncluded: shouldIncludeVat,
  })
}

export default usePrice
