import { forwardRef } from 'react'
import { FlexboxGrid } from 'rsuite'
import Link from 'next/link'
import { useRouter } from 'next/router'

import SafeTranslate from '../../common/SafeTranslate'
import AnimatedContentRotator from '../../common/AnimatedContentRotator'
import useIsOnMobile from '../../../services/useIsOnMobile'
import useUrls from '../../../services/useUrls'
import topBannerContent from '../../../utils/topBannerContent.json'
import { Country, ScreenSize, TOTAL_SATISFIED_CUSTOMERS } from '../../../utils/constants'
import { getCountryAndLocaleStrings } from '../../../utils/locales'

import styles from '../../../styles/NotificationHeader.module.less'
import buttonStyles from '../../../styles/CustomButtons.module.less'

const ANIMATION_DURATION = 1000
const DISPLAY_DURATION = 4000
const TRANSITION_DURATION = 1000

const NotificationHeader = forwardRef<HTMLDivElement>((_, ref) => {
  const { urlT } = useUrls()
  const isOnMobile = useIsOnMobile()
  const isOnTablet = useIsOnMobile(ScreenSize.md)
  const { locale: countryLandString } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryLandString)

  const getLinkComponent = (type: string | undefined) => (
    <Link
      href={urlT(type === 'catalogButton' ? '/my-catalogs' : '/products')}
      className={`${buttonStyles['link-button']} highlight-text font-semibold`}
    />
  )

  // Create an array of notification content components for the rotator
  const notificationContents = topBannerContent.map((content) => (
    <SafeTranslate
      key={content.key}
      i18nKey={`common:${content.key}`}
      components={{
        p: <p />,
        b: <b />,
        link: getLinkComponent(content.linkType),
      }}
      values={{
        country: Country[country],
      }}
    />
  ))

  return (
    <div
      data-testid="notification-header"
      ref={ref}
      className={`${styles['notification-header-container']} hide-on-print`}
    >
      <FlexboxGrid
        justify="space-between"
        className={`${styles['inner-container']} max-width-lg`}
      >
        <AnimatedContentRotator
          animationDuration={ANIMATION_DURATION}
          displayDuration={DISPLAY_DURATION}
          transitionDuration={TRANSITION_DURATION}
          enterPlacement="bottom"
          exitPlacement="top"
        >
          {notificationContents}
        </AnimatedContentRotator>
        {!isOnMobile && !isOnTablet && (
          <div>
            <img
              src="/icons/shield-icon.svg"
              alt="shield-icon"
              className={`${styles['icon-vertical-alignment']} margin-right-spacer-half`}
            />
            <SafeTranslate
              i18nKey="common:Over {{count}} satisfied customers"
              components={{ b: <b /> }}
              values={{ count: TOTAL_SATISFIED_CUSTOMERS }}
            />
          </div>
        )}
      </FlexboxGrid>
    </div>
  )
})

export default NotificationHeader
