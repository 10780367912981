import useSWR from 'swr'
import { FC, MutableRefObject, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { captureException } from '@sentry/nextjs'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'next/router'

import { IOptionsDocument } from '../../../utils/types/aimeosApi'
import { CustomerAddressResponse, CustomerResponse, EditAccountInput } from '../../../utils/types/customer'
import fetcher from '../../../utils/fetcher'
import { selectCustomerAddressHref } from './selectors'
import { extractFirstAndLastName } from '../../../utils/customer'
import { useAuth } from '../../../services/useAuth'
import { Customer } from '../../../utils/types/Product'
import useUrls from '../../../services/useUrls'
import { MODAL_HASHES } from '../../../utils/constants'
import useCyModel from '../../../services/useCyModel'
import { getCookie } from '../../../utils/cookies'
import { BillingAddressFormValues } from '../BillingAddressForm/utils'
import { DeliveryAddressFormValues } from '../DeliveryAddressForm/utils'
import { CustomerType } from '../../CustomerTypeSelection'

interface CustomerActionResponse {
  data: CustomerResponse | CustomerAddressResponse | null
  error: any
}

interface ICustomerContext {
  customerResponse: CustomerResponse | null
  error: any
  isLoading: boolean
  isNewAccount: MutableRefObject<boolean>
  isAccountCreationError: MutableRefObject<boolean>
  selectedSubAccount: Customer | null
  updateCustomer: (accountInfo: EditAccountInput) => Promise<CustomerActionResponse>
  automatedAccountCreation: (email: string) => Promise<void>
  deleteCustomer: (id: string) => Promise<CustomerActionResponse>
  updateBillingInfo: (billingInfo: BillingAddressFormValues) => Promise<CustomerActionResponse>
  addAddress: (address: DeliveryAddressFormValues & BillingAddressFormValues,
    newAddressPosition?: number) => Promise<CustomerActionResponse>
  updateAddress: (
    addressId: string,
    address: DeliveryAddressFormValues & BillingAddressFormValues,
  ) => Promise<CustomerActionResponse>
  deleteAddress: (addressId: string) => Promise<CustomerActionResponse>
  onSubAccountSelect: (selectedCustomer: Customer | null) => void
  updateCustomerType: (customertype: CustomerType) => Promise<CustomerActionResponse>
  customerType: CustomerType
}

const initialState = {
  customerResponse: null,
  selectedSubAccount: null,
} as ICustomerContext

const CustomerContext = createContext<ICustomerContext>(initialState as ICustomerContext)

const CustomerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const isNewAccount = useRef(false)
  const isAccountCreationError = useRef(false)

  const [selectedSubAccount, setSelectedSubAccount] = useState<Customer | null>(null)

  const apiUrl = process.env.NEXT_PUBLIC_API_URL
  const baseUrl = `${apiUrl}/jsonapi/default`
  const { user, registerCall, sendPasswordResetEmail, refresh, setIsVatIncluded } = useAuth()
  const { pushT } = useUrls()
  const { asPath } = useRouter()

  const fetchParams = useMemo(() => ({ method: 'OPTIONS' }), [])

  const { data: options } = useSWR<IOptionsDocument>([
    baseUrl,
    JSON.stringify(fetchParams),
  ])

  const resourceUrl = options?.meta?.resources.customer
  const customerAddressDefaultPosition = 10

  // User customer type to cookie sync happens PageLayout.tsx
  const [, customerType] = (getCookie('customerType') || []) as [string, CustomerType]

  const {
    data: customerResponse,
    error,
    mutate,
  } = useSWR<CustomerResponse>(resourceUrl && user ? `${resourceUrl}?include=customer/customer,customer/address,customer/property` : null)

  // Fetch customer data on user change due to impersonation
  useEffect(() => {
    if (user) {
      mutate()
    }
  }, [user])

  useEffect(() => {
    // Check if session expires on BE and customer response is empty then refresh user and login
    if (user && customerResponse && customerResponse?.data?.[0].id === null) {
      const asyncRefresh = async () => {
        await refresh()
        pushT(`${asPath.replace(MODAL_HASHES.LOGIN_URL_HASH, '')}${MODAL_HASHES.LOGIN_URL_HASH}`)
      }

      asyncRefresh()
    }
  }, [customerResponse?.data?.[0]?.id])

  const automatedAccountCreation = useCallback(async (email: string) => {
    try {
      const randomPassword = uuidv4()
      const registerAttempt = await registerCall({
        email,
        password: randomPassword,
        password_confirmation: randomPassword,
        supplier: false,
        customerType,
      })

      if (registerAttempt.status !== 'Error') {
        isNewAccount.current = true

        // If guest user does not has an account already then
        // send reset password request after register
        sendPasswordResetEmail(email, true)
      }
    } catch (err) {
      console.log(err)
      isAccountCreationError.current = true
    }
  }, [customerType])

  const updateCustomer: ICustomerContext['updateCustomer'] = useCallback(async (accountInfo: EditAccountInput) => {
    const { fullName, workEmail, role, phoneNumber } = accountInfo
    const { firstName, lastName } = extractFirstAndLastName(fullName)

    const customerResourceUrl = customerResponse?.data[0].links.self.href

    const data = {
      data: {
        attributes: {
          'customer.firstname': firstName,
          'customer.lastname': lastName,
          'customer.email': workEmail,
          'customer.title': role,
          'customer.telephone': phoneNumber,
        },
      },
    }

    try {
      const response = await fetcher(customerResourceUrl ?? '', 'PATCH', JSON.stringify(data))
      const respData = await response.json()
      await mutate(respData)

      return { data: respData, error: null }
    } catch (e) {
      captureException(e)
      return { data: null, error: e }
    }
  }, [customerResponse, mutate])

  const updateCustomerType: ICustomerContext['updateCustomerType'] = useCallback(async (selectedCustomerType: string) => {
    setIsVatIncluded(selectedCustomerType !== 'business')
    const customerResourceUrl = customerResponse?.data?.[0]?.links?.self.href

    const data = {
      data: {
        attributes: {
          'customer.customertype': selectedCustomerType,
        },
      },
    }

    try {
      const response = await fetcher(customerResourceUrl ?? '', 'PATCH', JSON.stringify(data))
      const respData = await response.json()
      await mutate(respData)
      refresh()

      return { data: respData, error: null }
    } catch (e) {
      captureException(e)
      return { data: null, error: e }
    }
  }, [customerResponse, mutate])

  const deleteCustomer: ICustomerContext['deleteCustomer'] = useCallback(async (url: string) => {
    try {
      const response = await fetcher(url, 'DELETE')
      const respData = await response.json()
      await mutate(respData)

      return { data: respData, error: null }
    } catch (e) {
      captureException(e)
      return { data: null, error: e }
    }
  }, [customerResponse, mutate])

  const updateBillingInfo: ICustomerContext['updateBillingInfo'] = useCallback(async (billingInfo: BillingAddressFormValues) => {
    const {
      address1 = '',
      businessId = '',
      city = '',
      country = 'FI',
      notes = '',
      postal = '',
    } = billingInfo

    const customerResourceUrl = customerResponse?.data?.[0].links.self.href

    const data = {
      data: {
        attributes: {
          'customer.address1': address1,
          'customer.city': city,
          'customer.countryid': country,
          'customer.postal': postal,
          'customer.businessid': businessId,
          'customer.paymentnotes': notes,
        },
      },
    }

    try {
      const response = await fetcher(customerResourceUrl ?? '', 'PATCH', JSON.stringify(data))
      const respData = await response.json()
      await mutate(respData)

      return { data: respData, error: null }
    } catch (e) {
      captureException(e)
      return { data: null, error: e }
    }
  }, [customerResponse, mutate])

  const addAddress: ICustomerContext['addAddress'] = useCallback(async (addressData, position = customerAddressDefaultPosition) => {
    const {
      address1,
      addressDetails = '', /** @deprecated data field, only show for old orders */
      notes = '',
      postal = '',
      city = '',
      country = '',
      company = '',
      businessId = '',
      email = '',
      phoneNumber = '',
      checkbox,
      invoiceType, // Used for billing address invoiceType
      type,
      recipientFirstName,
      recipientLastName,
    } = addressData

    const customerAddressResourceUrl = selectCustomerAddressHref(customerResponse)

    const data = {
      data: [{
        attributes: {
          'customer.address.address1': address1,
          'customer.address.postal': postal,
          'customer.address.city': city,
          'customer.address.countryid': country,
          'customer.address.addressdetails': invoiceType || addressDetails, /** @deprecated data field, only show for old orders */
          'customer.address.deliverynotes': notes,
          'customer.address.company': company,
          'customer.address.vatid': businessId,
          'customer.address.email': email,
          'customer.address.telephone': phoneNumber,
          'customer.address.firstname': recipientFirstName,
          'customer.address.lastname': recipientLastName,
          'customer.address.position': checkbox?.length ? 0 : position,
          ...(type && { 'customer.address.type': type }),
        },
      }],
    }

    try {
      const response = await fetcher(customerAddressResourceUrl ?? '', 'POST', JSON.stringify(data))
      const respData = await response.json()
      await mutate()

      return { data: respData, error: null }
    } catch (e) {
      captureException(e)
      return { data: null, error: e }
    }
  }, [customerResponse, mutate])

  const updateAddress: ICustomerContext['updateAddress'] = useCallback(async (addressId, addressData) => {
    const {
      address1,
      addressDetails,
      notes,
      postal,
      city,
      country,
      checkbox,
      company,
      businessId,
      email,
      phoneNumber,
      invoiceType,
      type,
      recipientFirstName,
      recipientLastName,
    } = addressData

    const customerAddressResourceUrl = `${selectCustomerAddressHref(customerResponse)}&relatedid=${addressId}`

    const data = {
      data: {
        attributes: {
          'customer.address.address1': address1,
          'customer.address.postal': postal,
          'customer.address.city': city,
          'customer.address.countryid': country,
          'customer.address.addressdetails': invoiceType || addressDetails,
          'customer.address.deliverynotes': notes,
          'customer.address.company': company,
          'customer.address.vatid': businessId,
          'customer.address.email': email,
          'customer.address.telephone': phoneNumber,
          'customer.address.firstname': recipientFirstName,
          'customer.address.lastname': recipientLastName,
          'customer.address.position': (checkbox && checkbox.length > 1) ? 0 : customerAddressDefaultPosition,
          ...(type && { 'customer.address.type': type }),
        },
      },
    }

    try {
      const response = await fetcher(customerAddressResourceUrl, 'PATCH', JSON.stringify(data))
      const respData = await response.json()
      await mutate()

      return { data: respData, error: null }
    } catch (e) {
      captureException(e)
      return { data: null, error: e }
    }
  }, [customerResponse, mutate])

  const deleteAddress: ICustomerContext['deleteAddress'] = useCallback(async (addressId) => {
    const deleteAddressUrl = `${apiUrl}/api/v1/delete/address?id=${user?.id}&related=address&relatedid=${addressId}`
    try {
      const response = await fetcher(deleteAddressUrl, 'DELETE')
      const respData = await response.json()
      await mutate()

      return { data: respData, error: null }
    } catch (e) {
      captureException(e)
      return { data: null, error: e }
    }
  }, [customerResponse, mutate])

  const onSubAccountSelect = useCallback((selectedAccount: Customer | null) => {
    setSelectedSubAccount(selectedAccount)
  }, [setSelectedSubAccount])

  useCyModel('Customer', { customer: customerResponse })

  return (
    <CustomerContext.Provider value={{
      customerResponse: customerResponse ?? null,
      isLoading: !customerResponse && !error,
      isNewAccount,
      isAccountCreationError,
      error,
      updateCustomer,
      automatedAccountCreation,
      deleteCustomer,
      updateBillingInfo,
      addAddress,
      updateAddress,
      deleteAddress,
      selectedSubAccount,
      onSubAccountSelect,
      updateCustomerType,
      customerType,
    }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export default CustomerProvider

export const useCustomer = () => useContext(CustomerContext)
