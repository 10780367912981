export type Option = {
  label: string | undefined
  value: string
}

export type GenericPickerProps = {
  value: string[] | string
  onChange: ((value: string[]) => void) | ((value: string) => void)
  options: Option[]
}

export type GenericPickerModel = Partial<{
  [key: string]: GenericPickerProps
}>

class GenericPicker implements GenericPickerModel {
  [key: string]: GenericPickerProps

  constructor(model: GenericPickerModel) {
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) {
        this[key] = model[key] as GenericPickerProps
      }
    })
  }
}

export default GenericPicker
