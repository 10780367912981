import { OverlayTriggerInstance } from 'rsuite/esm/Picker'

import { selectCartAddressEmail } from '../../../components/Cart/selectors'
import { CartContextType } from '../../../utils/types/Cart'

type CartModel = Partial<CartContextType> & Partial<{
  popup: {
    ref: React.MutableRefObject<OverlayTriggerInstance | null>
  }
}>

class Cart implements CartModel {
  cartState: CartModel['cartState']

  modifiedCart: CartModel['modifiedCart']

  isMovModalVisible: CartModel['isMovModalVisible']

  toggleMovModal: CartModel['toggleMovModal']

  refresh: CartModel['refresh']

  addProduct: CartModel['addProduct']

  updateProduct: CartModel['updateProduct']

  deleteProduct: CartModel['deleteProduct']

  addPayment: CartModel['addPayment']

  addSingleAddress: CartModel['addSingleAddress']

  addAddress: CartModel['addAddress']

  updateSingleAddress: CartModel['updateSingleAddress']

  updateAddress: CartModel['updateAddress']

  deleteAddresses: CartModel['deleteAddresses']

  updateCart: CartModel['updateCart']

  clearCart: CartModel['clearCart']

  createOrder: CartModel['createOrder']

  popup: CartModel['popup']

  constructor(model: CartModel) {
    this.cartState = model.cartState
    this.modifiedCart = model.modifiedCart
    this.isMovModalVisible = model.isMovModalVisible
    this.toggleMovModal = model.toggleMovModal
    this.refresh = model.refresh
    this.addProduct = model.addProduct
    this.updateProduct = model.updateProduct
    this.deleteProduct = model.deleteProduct
    this.addPayment = model.addPayment
    this.addSingleAddress = model.addSingleAddress
    this.addAddress = model.addAddress
    this.updateSingleAddress = model.updateSingleAddress
    this.updateAddress = model.updateAddress
    this.deleteAddresses = model.deleteAddresses
    this.updateCart = model.updateCart
    this.clearCart = model.clearCart
    this.createOrder = model.createOrder
    this.popup = model.popup

    this.getCartSupplierProductsCount = this.getCartSupplierProductsCount.bind(this)
    this.getCartEmail = this.getCartEmail.bind(this)
    this.closePopup = this.closePopup.bind(this)
  }

  getCartSupplierProductsCount() {
    return this.modifiedCart?.suppliers?.length || 0
  }

  getCartEmail(fallbackEmail: string) {
    if (!this.cartState?.cart) {
      return fallbackEmail
    }

    return selectCartAddressEmail(this.cartState.cart) || fallbackEmail
  }

  closePopup() {
    this.popup?.ref.current?.close()
  }
}

export default Cart
