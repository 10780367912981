import TagManager from 'react-gtm-module'

import { AnalyticsContext } from '../types/analytics'

const editAddress = (context: AnalyticsContext) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'edit_address',
      context,
    },
  })
}

export default editAddress
