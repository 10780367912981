import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { detect } from 'detect-browser'
import { Animation, Container, Content, CustomProvider as IntlProvider } from 'rsuite'
import dynamic from 'next/dynamic'
import en_GB from 'rsuite/locales/en_GB'
import fi_FI from 'rsuite/locales/fi_FI'
import sv_SE from 'rsuite/locales/sv_SE'
import de_DE from 'rsuite/locales/de_DE'

import Onboarding from '../Onboarding/Onboarding'
import CookieBanner from '../CookieBanner/CookieBanner'
import MobileBlocker from '../MobileBlocker'
import InvalidSessionModal from '../Modals/InvalidSessionModal'
import CheckoutFooter from './Footer/CheckoutFooter'
import CheckoutHeader from './Header/CheckoutHeader'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import { useAuth } from '../../services/useAuth'
import { isSuperUser, isSupplier } from '../../lib/supplier'
import useIsOnMobile from '../../services/useIsOnMobile'
import { MODAL_HASHES, ScreenSize } from '../../utils/constants'
import { HeaderContext } from './Header/hooks/headerContext'
import Header from './Header/Header'
import NotificationHeader from './Header/NotificationHeader'
import MobileHeader from './Header/MobileHeader'
import Footer from './Footer/Footer'
import useIsUrlHashIncluded from '../../services/useIsUrlHashIncluded'
import ChangeLanguageModal from '../Modals/ChangeLanguageModal'
import useLoginModal from '../../services/useLoginModal'
import UnsupportedBrowser from '../Modals/UnsupportedBrowserModal'
import { checkIsBrowserSupported } from '../../utils/util'
import SimpleHeader from './Header/SimpleHeader'
import { StrapiFooterResp } from '../../utils/types/strapi'
import withImpersonation from '../Impersonation/withImpersonation'
import { createCookie, deleteCookie } from '../../utils/cookies'
import { useCustomer } from '../BuyerProfile/Account/CustomerProvider'

import mobileContactStyles from '../../styles/MobileBlocker.module.less'
import pageLayoutStyles from '../../styles/PageLayout.module.less'
import headerStyles from '../../styles/Header.module.less'

// Not included in initial JS chunk for performance
const ContactModal = dynamic(() => import('../Modals/ContactModal'))
const RFQModal = dynamic(() => import('../Modals/ModalFlows/QuoteRequest/QuoteRequestModal'))
const SampleRequestModal = dynamic(() => import('../Modals/ModalFlows/SampleRequest/SampleRequestModal'))
const DocumentRequestModal = dynamic(() => import('../Modals/ModalFlows/DocumentRequest/DocumentRequestModal'))
const LoginModal = dynamic(() => import('../Modals/LoginModal'))
const ImpersonationToolbar = dynamic(() => import('../Impersonation/Toolbar'))
const SelectCustomerTypeModal = dynamic(() => import('../Modals/SelectCustomerTypeModal'))

interface LayoutProps {
  children?: ReactNode
  // Workaround to include footer content via SSG
  // TODO: Implement SSG footer globally to Layout component in Next.js 13+
  footerContentResp?: StrapiFooterResp
}

const Layout: FC<LayoutProps> = ({ children, footerContentResp }) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false)
  const [isBrowserUnSupported, setIsBrowserUnSupported] = useState(false)

  const { user, refresh, isImpersonated } = useAuth()
  const { customerType: cookieCustomerType, updateCustomerType } = useCustomer()
  const { locale: countryAndLocaleString, pathname, query } = useRouter()
  const isOnMobile = useIsOnMobile()
  const isOnTablet = useIsOnMobile(ScreenSize.md)
  const layoutContainer = useRef<HTMLDivElement>(null)

  const showSimpleHeader = pathname === '/authorize'

  const { locale } = getCountryAndLocaleStrings(countryAndLocaleString)
  const browser = detect()

  const {
    CONTACT_URL_HASH,
    RFQ_URL_HASH,
    SAMPLE_REQUEST_URL_HASH,
    DOCUMENT_REQUEST_URL_HASH,
    SELECT_CUSTOMER_TYPE_URL_HASH,
  } = MODAL_HASHES

  const renderContactModal = useIsUrlHashIncluded(CONTACT_URL_HASH)
  const renderRFQModal = useIsUrlHashIncluded(RFQ_URL_HASH)
  const renderSampleRequestModal = useIsUrlHashIncluded(SAMPLE_REQUEST_URL_HASH)
  const renderDocumentRequestModal = useIsUrlHashIncluded(DOCUMENT_REQUEST_URL_HASH)
  const renderSelectCustomerTypeModal = useIsUrlHashIncluded(SELECT_CUSTOMER_TYPE_URL_HASH)
  const { shouldShowLoginModal } = useLoginModal()

  let rsuiteLocale
  switch (locale) {
    case 'fi': {
      rsuiteLocale = fi_FI
      break
    }
    case 'sv': {
      rsuiteLocale = sv_SE
      break
    }
    case 'de': {
      rsuiteLocale = de_DE
      break
    }
    default: {
      rsuiteLocale = en_GB
      break
    }
  }

  // Used for components that need header / top header refs via prop drilling or context
  const notificationHeaderRef = useRef<HTMLDivElement>(null)
  const mainHeaderRef = useRef<HTMLDivElement>(null)

  // Check if the user is currently during the checkout process, before the checkout summary
  const isOnCheckoutStep = pathname === '/checkout/[step]' && query.step !== 'summary'
  const isOnCartPage = pathname === '/cart'

  useEffect(() => {
    setIsBrowserUnSupported(!checkIsBrowserSupported(browser?.name, browser?.version))
  }, [])

  // Sync customer type between cookie and logged in user data
  useEffect(() => {
    const syncCustomerType = async () => {
      if (cookieCustomerType) {
        // Ensure old cookie with out-dated domain format is replaced with new value
        // TODO: Remove once all users have updated to the new cookie domain format – 1.1.2026

        // Thoroughly delete the cookie from all possible domain variations
        deleteCookie('customerType', null)

        // Create the cookie with the current standard domain format
        createCookie('customerType', cookieCustomerType)
      }

      // Continue with existing sync logic
      // If user doesn't have customer type, update it with the cookie value
      if (user && (user.customertype === '' || user.customertype === null) && cookieCustomerType) {
        await updateCustomerType(cookieCustomerType)
        refresh()
      } else if (user && user.customertype !== cookieCustomerType) {
        // If user has customer type, but it doesn't match the cookie value, update the cookie
        createCookie('customerType', user.customertype)
      } else if (!cookieCustomerType) {
        // If the cookie doesn't exist, create a default cookie value
        createCookie('customerType', 'business')
      }
    }

    syncCustomerType()
  }, [user?.email, user?.customertype])

  const renderHeaderComponent = () => {
    if (isOnCheckoutStep) {
      return <CheckoutHeader ref={mainHeaderRef} />
    }

    if (showSimpleHeader) {
      return <SimpleHeader ref={mainHeaderRef} />
    }

    return (
      <>
        <Header ref={mainHeaderRef} />
        <div
          className={`${headerStyles.header} ${pageLayoutStyles['mobile-header']} hide-on-print`}
          ref={isOnMobile ? mainHeaderRef : undefined}
        >
          <MobileHeader ref={mainHeaderRef} />
        </div>
      </>

    )
  }

  const shouldShowImpersonationToolbar = !!user && (isSuperUser(user) || isImpersonated)

  return (
    <>
      {shouldShowImpersonationToolbar && (
        <Animation.Slide
          in={shouldShowImpersonationToolbar}
          placement="bottom"
        >
          {(props, ref) => (
            <div
              {...props}
              ref={ref}
              className={`${pageLayoutStyles['superuser-toolbar']} ${props.className}`}
            >
              <ImpersonationToolbar />
            </div>
          )}
        </Animation.Slide>
      )}
      <InvalidSessionModal />
      <HeaderContext.Provider value={mainHeaderRef}>
        <Container
          ref={layoutContainer}
          className={`${pageLayoutStyles['layout-container']} ${isLanguageModalOpen ? 'language-modal-open' : ''}`}
        >
          {!isOnCheckoutStep && !isOnMobile && <NotificationHeader ref={notificationHeaderRef} />}
          {renderHeaderComponent()}
          <MobileBlocker />
          <IntlProvider locale={rsuiteLocale}>
            <Content>
              {isSupplier(user)
                ? (
                  <Onboarding>
                    {children}
                  </Onboarding>
                )
                : (
                  <>
                    { children }
                  </>
                )}
              {/*
               The below dynamic modals can be used via the following hooks:
                - useContactModal
                - useRFQModal
                - useSampleRequestModal
              */}
              {renderContactModal && (
              <ContactModal
                staticBackdrop={isOnMobile}
                backdropClassName={isOnMobile ? mobileContactStyles['mobile-backdrop'] : ''}
              />
              )}
              {shouldShowLoginModal && <LoginModal />}
              {renderRFQModal && <RFQModal />}
              {renderSampleRequestModal && <SampleRequestModal />}
              {renderDocumentRequestModal && <DocumentRequestModal />}
              {renderSelectCustomerTypeModal && <SelectCustomerTypeModal />}
              <ChangeLanguageModal
                isOpen={isLanguageModalOpen}
                setIsOpen={setIsLanguageModalOpen}
                container={layoutContainer?.current || undefined}
                backdropClassName="language-modal-backdrop"
              />
            </Content>
          </IntlProvider>
          {isOnCheckoutStep
            ? <CheckoutFooter />
            : <Footer footerContentResp={footerContentResp} />}
        </Container>
      </HeaderContext.Provider>

      {isBrowserUnSupported && <UnsupportedBrowser />}
      <CookieBanner />
    </>
  )
}
export default withImpersonation(Layout)
