import TagManager from 'react-gtm-module'

import { roundToTwoDecimals } from '../math'
import { emptyEcomDataLayer, getCartTotalDiscount, getCartTotalValue, getCartEcomEventItems } from './utils'
import { CartResponse, WithSuppliers } from '../types/Order'
import { ActiveCountryId } from '../constants'
import { Product } from '../types/Product'

interface ViewCartParams {
  cartData: WithSuppliers<CartResponse>
  context?: 'modify_reorder'
  country: ActiveCountryId
  getLocalizedProductUrl: (product?: Product) => string
  isVatIncluded: boolean
}

const viewCart = ({
  cartData,
  context,
  country,
  getLocalizedProductUrl,
  isVatIncluded,
}: ViewCartParams) => {
  const { data } = cartData

  if (!data) {
    return
  }

  const items = getCartEcomEventItems({
    cartData,
    country,
    getLocalizedProductUrl,
    isVatIncluded,
  })

  const isUnderMov = items.some((item) => item.is_under_mov)

  emptyEcomDataLayer() // First empty datalayer
  TagManager.dataLayer({ dataLayer: {
    event: 'view_cart',
    context: context || '',
    is_under_mov: isUnderMov,
    ecommerce: {
      affiliation: 'Droppe Marketplace',
      value: roundToTwoDecimals(data.attributes['order.price']),
      tax: roundToTwoDecimals(data.attributes['order.taxvalue']),
      shipping: roundToTwoDecimals(data.attributes['order.costs']),
      currency: data.attributes['order.currencyid'],
      coupon: 'none',
      totalValue: getCartTotalValue(cartData),
      discount: getCartTotalDiscount(cartData),
      items,
    },
  } })
}

export default viewCart
