import { useState, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'

import { Product } from '../../utils/types/Product'

interface UseLocalStorageViewedProductsReturn {
  addViewedSku: (product: Product) => void
  viewedSkus: string[]
  clearViewedSkus: () => void
  isLoading: boolean
  refresh: () => void
}

const STORAGE_KEY = 'viewedSkus'
const MAX_SKUS = 100

/**
 * Hook to track and store product SKUs that a user has viewed in local storage
 *
 * Maintains a list of up to 100 most recently viewed product SKUs in local storage
 * Uses FIFO (First In, First Out) to remove oldest SKUs when the limit is reached
 */
const useLocalStorageViewedProducts = (): UseLocalStorageViewedProductsReturn => {
  const [viewedSkus, setViewedSkus] = useState<string[]>([])
  const [isStorageAvailable, setIsStorageAvailable] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const { asPath } = useRouter()

  // Read from localStorage
  const refresh = useCallback(() => {
    if (!isStorageAvailable) return

    try {
      const storedSkus = localStorage.getItem(STORAGE_KEY)
      if (storedSkus) {
        const parsedSkus = JSON.parse(storedSkus)
        if (Array.isArray(parsedSkus)) {
          setViewedSkus(parsedSkus)
          setIsLoading(false)
        }
      } else {
        setViewedSkus([])
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error reading viewed SKUs from local storage:', error)
      setIsLoading(false)
    }
  }, [isStorageAvailable])

  // Check if localStorage is available
  useEffect(() => {
    try {
      const testKey = '__test__'
      localStorage.setItem(testKey, testKey)
      localStorage.removeItem(testKey)
      setIsStorageAvailable(true)
    } catch (e) {
      console.warn('Local storage is not available. Viewed products will not be persisted.')
      setIsStorageAvailable(false)
    }
  }, [])

  // Initialize from local storage
  useEffect(() => {
    setIsLoading(true) // Set loading to true before refreshing
    refresh()
  }, [asPath, refresh])

  // Listen for storage events (when localStorage changes in other tabs)
  useEffect(() => {
    if (!isStorageAvailable) return

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === STORAGE_KEY) {
        refresh()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    // Cleanup function
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [isStorageAvailable, refresh])

  /**
   * Add a product SKU to the viewed items list
   * - Removes the SKU if it already exists (to avoid duplicates)
   * - Adds the new SKU to the end (most recent)
   * - Limits to MAX_SKUS by removing from the beginning (oldest)
   * - Saves directly to localStorage to avoid race conditions
   */
  const addViewedSku = (product: Product) => {
    if (!product || !product['product.code']) return
    setIsLoading(true)

    const sku = product['product.code']

    setViewedSkus((prevSkus) => {
      // Remove the SKU if it already exists (to avoid duplicates)
      const filteredSkus = prevSkus.filter((s) => s !== sku)

      // Add the new SKU to the end (most recent)
      const newSkus = [...filteredSkus, sku]

      // Limit to MAX_SKUS by removing from the beginning (oldest)
      const updatedSkus = newSkus.slice(-MAX_SKUS)

      // Save directly to localStorage
      if (isStorageAvailable) {
        try {
          localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedSkus))
          setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
        }
      } else {
        setIsLoading(false) // Ensure loading is set to false even when localStorage isn't available
      }

      return updatedSkus
    })
  }

  /**
   * Clear all viewed SKUs from local storage and state
   */
  const clearViewedSkus = useCallback(() => {
    setIsLoading(true)
    setViewedSkus([])

    if (!isStorageAvailable) {
      setIsLoading(false)
      return
    }

    try {
      localStorage.removeItem(STORAGE_KEY)
      setIsLoading(false)
    } catch (error) {
      console.error('Error clearing viewed SKUs from local storage:', error)
      setIsLoading(false)
    }
  }, [isStorageAvailable])

  return {
    addViewedSku,
    clearViewedSkus,
    viewedSkus,
    isLoading,
    refresh,
  }
}

export default useLocalStorageViewedProducts
