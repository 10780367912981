import { forwardRef, useImperativeHandle, useRef } from 'react'
import { Col, Divider, FlexboxGrid } from 'rsuite'
import { useRouter } from 'next/router'

import useInternalTraffic from '../../hooks/useInternalTraffic'
import Navigation from './Navigation'
import CategoryHeader from './CategoryHeader'
import InternalTrafficButton from '../../InternalTrafficButton'

import styles from '../../../styles/Header.module.less'
import pageLayoutStyles from '../../../styles/PageLayout.module.less'

const Header = forwardRef<HTMLElement>((_, ref) => {
  const { isInternalTraffic } = useInternalTraffic()

  const headerRef = useRef<HTMLDivElement>(null)
  const { pathname } = useRouter()
  const bottomMargin = pathname.includes('/manage/product') ? '' : 'margin-bottom-spacer-double'

  useImperativeHandle(ref, () => headerRef.current!, [headerRef])

  return (
    <header
      ref={headerRef}
      data-testid="desktop-header"
      className={`
        ${pageLayoutStyles['desktop-header']} 
        ${styles.header} hide-on-print
        ${bottomMargin}
      `}
    >
      <Navigation
        className="max-width-lg padding-left-spacer-double padding-right-spacer-double"
        headerRef={headerRef}
      />

      <Divider className={styles['header-divider']} />

      <FlexboxGrid className="align-baseline max-width-lg padding-left-spacer-double padding-right-spacer-double">
        <FlexboxGrid.Item
          as={Col}
          xl={isInternalTraffic ? 21 : 24}
          lg={isInternalTraffic ? 20 : 24}
        >
          <CategoryHeader headerRef={headerRef} />
        </FlexboxGrid.Item>
        {isInternalTraffic && (
          <FlexboxGrid.Item
            as={Col}
            xl={3}
            lg={4}
            className={styles['internal-cookie']}
          >
            <InternalTrafficButton />
          </FlexboxGrid.Item>
        )}
      </FlexboxGrid>
    </header>
  )
})

export default Header
