import { ClipboardEvent, MouseEvent, WheelEvent } from 'react'

import { isNumber } from './math'
import { ClickType } from './types/analytics'

const throttle = <T extends (...args: any[]) => any>(func: T, ms: number) => {
  let inThrottle = false
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any
    if (!inThrottle) {
      inThrottle = true
      result = func(...args)
      setTimeout(() => {
        inThrottle = false
      }, ms)
    }
    return result
  }
}

const debounce = <T extends (...args: any[]) => any>(func: T, ms: number) => {
  let timeout: ReturnType<typeof setTimeout>
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      result = func(...args)
    }, ms)
    return result
  }
}

const preventTextOnPaste = (handleChange: Function) => (e: ClipboardEvent<HTMLInputElement>) => {
  const pastedText = e.clipboardData.getData('text/plain')

  if (!isNumber(pastedText)) {
    setTimeout(() => handleChange(0))
  }
}

const disableEditOnScroll = (
  e: WheelEvent<HTMLFormElement> | WheelEvent<HTMLInputElement>,
) => (e.target as HTMLInputElement).blur()

export {
  throttle,
  debounce,
  preventTextOnPaste,
  disableEditOnScroll,
}

export const getClickType = (event: MouseEvent<Element>): ClickType => {
  // Right click (contextmenu)
  if (event.type === 'contextmenu') return 'right'

  // Middle mouse button click
  if (event.button === 1) return 'new_tab'

  // Cmd/Ctrl + click
  if ((event.metaKey || event.ctrlKey) && event.button === 0) return 'new_tab'

  // Shift + click
  if (event.shiftKey && event.button === 0) return 'new_tab'

  return undefined
}
